import React from 'react';
import './ellipsis.css'

function Ellipsis() {
    return (
        <div className='ellipsis'> 
            <div className="ellipsis_box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Ellipsis
