import React from 'react';
import FetchDropdownLinks from './FetchDropdownLinks';    
import UseLang from '../../../hook/UseLang';
import { Link } from 'react-router-dom';

const Dropdown = props => {


    const { item_category, isItem, item_name, eventID, menuLang, id_menu, url_api } = props;
     
 
    return (
        <ul className={`mainNavMenu_dropdownBox ${isItem === item_category ? 'open-dropdown' : ''}`}  >
            <FetchDropdownLinks
                url_api={url_api}
                id_menu={id_menu}
                menuLang={menuLang}
                eventID={eventID}
                item_name={item_name}
                item_category={item_category} 
            />     
            {
                item_category === 'media' ? 
                <>
                {/* <li> 
                    <a className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/es/streaming'><UseLang es>Transmisión en vivo</UseLang></a>
                    <a className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/en/streaming'><UseLang en>Live streaming</UseLang></a>
                    <a className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/pt-br/streaming'><UseLang pt_br>Transmissão ao vivo</UseLang> </a>
                </li>
                <li> 
                    <a className='mainNavMenu_dropdownBox_link' href='https://rir.la/lacnic40'> 
                        <UseLang es>Zoom Eventos</UseLang>
                        <UseLang en>Events Zoom</UseLang>
                        <UseLang pt_br>Zoom Eventos</UseLang> 
                    </a>
                </li> */}
                <li> 
                    <Link className='mainNavMenu_dropdownBox_link' to='?multimedia=photos'> 
                        <UseLang es>Fotos</UseLang>
                        <UseLang en>Photos</UseLang>
                        <UseLang pt_br>Fotos</UseLang> 
                    </Link>
                </li>
                </> : null
            }
        </ul>
    )
}

export default Dropdown

