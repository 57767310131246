import React from 'react';  
import FooterWrapper from './FooterWrapper';
import FooterTop from './FooterTop';
import FooterBottom from './FooterBottom';
 

function Footer(props) {
    const { className } = props 
    return (
        <FooterWrapper className={className}>  
            <FooterTop /> 
            <FooterBottom /> 
        </FooterWrapper>
    )
}

export default Footer
