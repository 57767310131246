import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../../context/DataContext';

const TableRow = ({ id, children }) => {
    const [addClass, setAddClass] = useState('');
    const { selectedDateStr, tableStyles } = useContext(SessionContext);
    useEffect(() => { 
        const fila = document.getElementById(id);

        if (fila) {
            // Cuenta los elementos <td> y <th> en la fila
            const cantidadDeTD = fila.querySelectorAll('td').length;
            const cantidadDeTH = fila.querySelectorAll('th').length;
            const totalElementos = cantidadDeTD + cantidadDeTH;

            // Actualiza la clase del tr según el valor de totalElementos
            if (totalElementos === 0 && selectedDateStr && tableStyles === 'ResponsiveTable' ) {
                setAddClass('EmptyRow');
            } else {
                setAddClass('');
            } 
        }
    }, [id, selectedDateStr, tableStyles]);

     

    return (
        <tr id={id} className={`row ${addClass}`}>
            {children}
        </tr> 
    );
};

export default TableRow;
