import React  from 'react'
import Modal from '../../../../components/interface/modal/Modal';
import Cocktail from './Cocktail';
import Runners from './Runners';
import Social from './Social';
import './social-activities.css'
import Beer from './Beer';

const SocialActivities = props => {
    const { display, closeModal, socialActivity } = props;
    
    return (
        <Modal show={display} onCancel={closeModal} className={`socials_activities ${socialActivity === 'runners' ? 'lacnic_runners':'' ||socialActivity === 'social'? 'socia_event':'' || socialActivity === 'cocktail'? 'cocktail':'' || socialActivity === 'beer-and-gear'? 'beer-and-gear':'' || socialActivity === 'beer-and-peer'? 'beer-and-peer':''}`}>
            <div className='close_modal_social'>
                <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
            </div> 
            {socialActivity === 'runners' ? <><Runners  /></> : undefined}
            {socialActivity === 'cocktail' ? <><Cocktail  /></> : undefined}
            {socialActivity === 'social' ? <><Social /></> : undefined} 
            {socialActivity === 'beer-and-gear' ? <><Beer event='gear' /></> : undefined} 
            {socialActivity === 'beer-and-peer' ? <><Beer event='peer'  /></> : undefined} 
        </Modal>
    )
}

export default SocialActivities
