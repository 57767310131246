import React from 'react' 

function SponsorsWrapper(props) {
    const {children, className} = props
    return (
        <article className='main_sponsors'>
            <div className={`sponsors_list ${className}`}>
                {children}
            </div>
        </article>
    )
}

export default SponsorsWrapper
