import React, { useState, useEffect } from 'react';
import { PostContext } from './DataContext';
import axios from "axios";

export const PostState = ({ children }) => {

    const [id, setId] = useState();
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [post_slug, setPostSlug] = useState();
    const [status, setStatus] = useState('private');
    const [button, setButton] = useState([]);
    const [langSlugA, setLangSlugA] = useState([]);
    const [langSlugB, setLangSlugB] = useState([]);
    
    const [postID, setPostID] = useState(159);
    const [apiURL, setApiURL] = useState('https://apievt.lacnic.net/wp-json/wp/v2/posts');
    const [charging, setCharging] = useState(false); 
    const [accordion, setAccordion] = useState([]);
    const [apps, setApps] = useState([]);
    const [sessionId, setSessionId] = useState(); 
    const [data, setData] = useState([]); 

    useEffect(() => {
        let cancel = false;
        const fetchPosts = async () => {
            setCharging(true);
            try {
                const responseJSON = await axios.get(`${apiURL}/${postID}`);
                if (!cancel) {
                    const responseData = responseJSON.data;
                    setData(responseData); 
                    if (responseData.wpml_translations) {
                        setLangSlugA(responseData.wpml_translations[0].slug)
                        setLangSlugB(responseData.wpml_translations[1].slug)
                    }
                    if (responseData.id) { setId(responseData.id) }
                    if (responseData.title.rendered) { setTitle(responseData.title.rendered); }
                    setContent(responseData.content.rendered)
                    if (responseJSON.data.status) { setStatus(responseData.status); }
                    setPostSlug(responseData.slug);
                    if (responseData.acf.create_accordion) { setAccordion(responseData.acf.create_accordion) };
                    if (responseData.acf.apps) { setApps(responseData.acf.apps) }
                    if (responseData.acf.buttons[0]) { setButton(responseData.acf.buttons[0]) }
                    if (responseData.agenda_sessions) { setSessionId(responseData.agenda_sessions) } 
                }
            } catch (error) {
                console.error(error);
            } finally {
                setCharging(false);
            }
        };
    
        fetchPosts();
    
        return () => {
            cancel = true;
        };
    }, [postID, apiURL]);
      
    const handleID = (value) => {
        setPostID(value)
    }
    const handleApiURL = (url) => {
        setApiURL(url)
    }

    const handleLoading = (value) => {
        setCharging(value)
    }  

    const [loading, setLoading] = useState(true);

    const [blogData, setBlogData] = useState([]);
    useEffect(() => {
        let cancel = false;
        const newsPost = async () => {
            setCharging(true);
            try {
                const responseJSON = await axios.get(`https://blog.lacnic.net/wp-json/wp/v2/pages/12908`);
                if (!cancel) {
                    const responseData = responseJSON.data;
                    setBlogData(responseData);  
                }
            } catch (error) {
                console.error(error);
            } finally {
                setCharging(false);
            }
        };
    
        newsPost();
    
        return () => {
            cancel = true;
        };
    }, [postID, apiURL]);

    return (
        <PostContext.Provider value={{
            handleID,
            charging,
            title,
            content, handleApiURL,
            accordion, post_slug, apps, id, button, handleLoading, status, langSlugA, langSlugB, 
            sessionId,
            loading, setLoading, data, blogData, postID
        }}>
            {children}
        </PostContext.Provider>
    );
}

