import React from 'react'
import { useParams } from 'react-router-dom';
import slugify from 'react-slugify';
import UseHTML from '../../../components/hook/UseHTML';   

function FellowshipProgramItem(props) {
    const { accordion, page_number } = props;
    const { fellowship } = useParams();
  
    return (
      <>
        {accordion?.map((item) => {
          const shouldRender =
            (fellowship === slugify(item.label) && item.id === `${page_number}`) ||
            (fellowship === undefined && item.id === '6');
  
          if (shouldRender) {
            return (
              <div key={item.id}>
                <h2>
                  <em>{item.label}</em>
                </h2>
                <UseHTML html={item.content} />
              </div>
            );
          }
  
          return null;
        })}
      </>
    );
  }
  
  export default FellowshipProgramItem;
  
