import React from 'react'
import PreparatoryActivityButton from './PreparatoryActivityButton';
import UseTitleHTML from '../../../../components/hook/UseTitleHTML';

const AgendaHeader = props => {
    const { title } = props;
    return (
        <article className='agenda_header'>
            <div>
                <UseTitleHTML html={title} />
            </div>
            <PreparatoryActivityButton/>
        </article>
    )
}

export default AgendaHeader
