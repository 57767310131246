import React from 'react'; 
import { LangState } from './LangState';
import { SponsorState } from './SponsorState'; 
import { PostState } from './PostState';
import { MenuState } from './MenuState';
import { TimeZoneState } from './TimeZoneState';
import { SessionState } from './SessionState';
import { BiographyState } from './BiographyState';

export default function MasterState({ children }) {
  return (
    <LangState>
        <SponsorState> 
            <PostState>
              <MenuState >
                <TimeZoneState>
                  <SessionState>
                    <BiographyState>
                    {children}
                    </BiographyState>
                  </SessionState>
                </TimeZoneState>
              </MenuState>
            </PostState> 
        </SponsorState>
    </LangState>
  )
}