import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'; 

const Filters = props => {
    const { content, header, image } = props;
    return (
        <div className='filters'>
            <figure><img src={`${image}`} alt={header} /></figure>
            <div>
                <h2>{header}</h2>
                <UseHTML html={content} />
                <div className='filters_buttons'>
                    <a href="https://bit.ly/InstLogoL40" target="_blank" rel="noreferrer" className='button blue'>Instagram</a>
                    <a href="https://bit.ly/FbkLogoL40" target="_blank" rel="noreferrer" className='button blue'>Facebook</a>
                </div>
            </div>
        </div>
    )
}

export default Filters
 