
import React from 'react'
import useUrlParams from '../../../components/hook/useUrlParams'
import Modal from '../../../components/interface/modal/Modal'
import JoinActivity from './JoinActivity';
import AccessCode from './AccessCode';
import UrlAndCode from '../../../components/interface/url-and-code/UrlAndCode';
import UseHTML from '../../../components/hook/UseHTML';


const WebinarModal = (props) => {
    const { paramValue, display, /* handleParam,  */closeModal } = useUrlParams('webinar', ['propuestas-de-politicas-fpp', 'configurar-servidores-linux-solamente-con-ipv6', 'camino-hacia-redes-ipv6-only', 'que-puedo-encontrar-en-el-evento']);
    const { contents } = props;
    return (
        <Modal onCancel={closeModal} show={display} paramValue='webinar' className='preparatory_activity'>
            <div className='close_preparatory_activity'>
                <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true" ></i></button>
            </div>

            <div className='preparatory_activity_modal'>
                {paramValue === 'propuestas-de-politicas-fpp' ?
                    <>{contents[1] ?
                        <div className='' id='webinar-propuestas-de-politicas-fpp-lacnic-40'>
                            <div>
                                <h2 className='preparatory_title'>{contents[1]?.label}</h2>
                                <UseHTML className="preparatory_content" html={contents[1]?.content} />
                                {
                                    contents[1]?.state ? <>
                                        <JoinActivity />
                                        {/* false para no mostrar; true cuando hay código de acceso */}
                                        <AccessCode code={false} />
                                        <UrlAndCode url_text='https://us02web.zoom.us/j/85776498756' url='https://us02web.zoom.us/j/85776498756?pwd=V1ZDVVZIamFpd3U5cmhlY0NrSjVQdz09' code='none' />
                                    </>
                                        : null}
                            </div>
                        </div> : null
                    }</>
                    : undefined
                }
                {
                    paramValue === 'configurar-servidores-linux-solamente-con-ipv6' ? <> {contents[0] ?
                        <div id='webinar-configurar-servidores-linux-solamente-con-ipv6'>
                            <div>
                                <h2 className='preparatory_title'>{contents[0].label}</h2>
                                <UseHTML className="preparatory_content" html={contents[0].content} />
                                {
                                    contents[0].state ? <>
                                        <JoinActivity />
                                        {/* false para no mostrar; true cuando hay código de acceso */}
                                        <AccessCode code={false} />
                                        <UrlAndCode url_text='https://us02web.zoom.us/j/85607666528' url='https://us02web.zoom.us/j/85607666528?pwd=R0N2d1dCZlJnWC9Dc2ZGTDZYWjBZZz09' code='none' />
                                    </>
                                : null}
                            </div>
                        </div> : null
                    }</> : undefined
                }
                {
                    paramValue === 'camino-hacia-redes-ipv6-only' ? <> {contents[2] ?
                        <div>
                            <h2 className='preparatory_title'>{contents[2].label}</h2>
                            <UseHTML className="preparatory_content" html={contents[2].content} />
                            {
                                contents[2].state ? <>
                                    <JoinActivity />
                                    {/* false para no mostrar; true cuando hay código de acceso */}
                                    <AccessCode code={false} />
                                    <UrlAndCode url_text='https://us02web.zoom.us/j/81426291250' url='https://us02web.zoom.us/j/81426291250?pwd=SUNRK3lvTzJPbEkyU0d2eUtsUkc5dz09' code='none' />
                                </>
                                    : null}
                        </div>

                        : null
                    }</> : undefined
                }
                 {
                    paramValue === 'que-puedo-encontrar-en-el-evento' ? <> {contents[3] ?
                        <div>
                            <h2 className='preparatory_title'>{contents[3].label}</h2>
                            <UseHTML className="preparatory_content" html={contents[3].content} />
                            {
                                contents[3].state ? <>
                                    <JoinActivity />
                                    {/* false para no mostrar; true cuando hay código de acceso */}
                                    <AccessCode code={false} />
                                    <UrlAndCode url_text='https://us02web.zoom.us/j/81602626453' url='https://us02web.zoom.us/j/81602626453?pwd=ZURxbWJqT0djbmlDY0VjdWVZWDY5Zz09' code='none' />
                                </>
                                    : null}
                        </div>

                        : null
                    }</> : undefined
                }



            </div>
        </Modal>

    )
}

export default WebinarModal

