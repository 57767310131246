import React from 'react'

const SpeakerType = props => {
    const { speaker_type, plural, singular } = props; 
    return (
        <p className='speaker_title'>
            {speaker_type.length > 1 ? plural : singular}
        :</p>
    )
}

export default SpeakerType
