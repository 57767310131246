import React from 'react'

const UseHTML = props => {
    const { children, html, className, onClick } = props;
     
    return (
        <>
           {className ? 
           <>
            <div className={`${className}`} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
            {children} </>: 
            <>
            <div onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
            {children}
            </>}
        </>
    )
}

export default UseHTML;
