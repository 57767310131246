import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const FeeIncludes = props => {
    const {content} = props;
    return (
        <article>
            <div>
                <UseHTML className='includes' html={content}/>
            </div>
        </article>
    )
}

export default FeeIncludes
