import React, { useState, useEffect } from 'react';
import UseLang from '../../../../components/hook/UseLang';

function PreparatoryActivityButton() {
    const [contenidoAMostrar, setContenidoAMostrar] = useState([]);

    useEffect(() => {
        // Obtener la fecha actual
        const fechaActual = new Date();

        // Definir las fechas de referencia
        const fecha1 = new Date('2023-08-31'); // Fecha 1
        const fecha2 = new Date('2023-09-14'); // Fecha 2
        const fecha3 = new Date('2023-09-21'); // Fecha 3
        const fecha4 = new Date('2023-09-27'); // Fecha 3

        // Creo el contenido por idioma
        setContenidoAMostrar(
            <>
                {fechaActual < fecha1 && (
                    <>
                        <UseLang es>
                        <a className='PreparatoryAcrivityButton' href="/es/programa/actividad-preparatoria?webinar=configurar-servidores-linux-solamente-con-ipv6">Actividad preparatoria: Webinar Configurar servidores Linux solamente con IPv6 - 31/08/23 - 19:00 (UTC)</a>
                        </UseLang>
                        <UseLang en>
                            <a className='PreparatoryAcrivityButton' href="/en/programme/preparatory-activity?webinar=configurar-servidores-linux-solamente-con-ipv6">Preparatory Activity: Webinar Configuring IPv6-Only Linux Servers - 31/08/23 - 19:00 (UTC)</a>
                        </UseLang>
                        <UseLang pt_br>
                            <a className='PreparatoryAcrivityButton' href="/pt-br/programa/atividade-preparatoria?webinar=configurar-servidores-linux-solamente-con-ipv6">Atividade Preparatoria: Webinar Configurar servidores Linux somente com IPv6 - 31/08/23 - 19:00 (UTC)</a>
                        </UseLang>
                    </>
                )}

                {fechaActual < fecha2 && (
                    <>
                        <UseLang es>
                        <a className='PreparatoryAcrivityButton' href="/es/programa/actividad-preparatoria?webinar=camino-hacia-redes-ipv6-only">Actividad preparatoria: Webinar Camino hacia redes IPv6 Only - 13/09/23 - 19:00 (UTC)</a>
                        </UseLang>
                        <UseLang en>
                            <a className='PreparatoryAcrivityButton' href="/en/programme/preparatory-activity?webinar=camino-hacia-redes-ipv6-only">Preparatory Activity: The Road Towards IPv6-Only Networks Webinar - 13/09/23 - 19:00 (UTC)</a>
                        </UseLang>
                        <UseLang pt_br>
                            <a className='PreparatoryAcrivityButton' href="/pt-br/programa/atividade-preparatoria?webinar=camino-hacia-redes-ipv6-only">Atividade Preparatoria: Caminho para as redes só IPv6 - 13/09/23 - 19:00 (UTC)</a>
                        </UseLang>
                         
                    </>
                )}
                {fechaActual < fecha3 && (
                    <>
                        <UseLang es>
                        <a className='PreparatoryAcrivityButton' href="/es/programa/actividad-preparatoria?webinar=que-puedo-encontrar-en-el-evento">Actividad preparatoria: Webinar ¿Qué puedo encontrar en el evento? - 20/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                        <UseLang en>
                            <a className='PreparatoryAcrivityButton' href="/en/programme/preparatory-activity?webinar=que-puedo-encontrar-en-el-evento">Preparatory Activity: Webinar What You Will Find at the Event - 20/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                        <UseLang pt_br>
                            <a className='PreparatoryAcrivityButton' href="/pt-br/programa/atividade-preparatoria?webinar=que-puedo-encontrar-en-el-evento">Atividade Preparatoria: Webinar O que posso encontrar no evento? - 20/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                         
                    </>
                )}
                {fechaActual < fecha4 && (
                    <>
                        <UseLang es>
                        <a className='PreparatoryAcrivityButton' href="/es/programa/actividad-preparatoria?webinar=propuestas-de-politicas-fpp">Actividad preparatoria: Webinar Propuestas de políticas FPP - 26/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                        <UseLang en>
                            <a className='PreparatoryAcrivityButton' href="/en/programme/preparatory-activity?webinar=propuestas-de-politicas-fpp">Preparatory Activity: Webinar Policy Proposals at the PPF - 26/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                        <UseLang pt_br>
                            <a className='PreparatoryAcrivityButton' href="/pt-br/programa/atividade-preparatoria?webinar=propuestas-de-politicas-fpp">Atividade Preparatoria: Webinar Propostas de políticas FPP - 26/09/23 - 18:00 (UTC)</a>
                        </UseLang>
                         
                    </>
                )}
                 
 
            </>
        );
    }, []);

    return (
        <div>
            {contenidoAMostrar}
        </div>
    )
}

export default PreparatoryActivityButton;
 