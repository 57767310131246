import React from 'react';
import { Link } from 'react-router-dom'; 
import UseLang from '../../../../../components/hook/UseLang';

function GoBackAgenda() {
    return (
        <>
            <UseLang es>
                <Link to='../es/programa/agenda' className='go_back_agenda'><i className='icon-arrow-left'></i> Volver a la agenda</Link>
            </UseLang>
            <UseLang en>
                <Link to='../en/programme/agenda' className='go_back_agenda'><i className='icon-arrow-left'></i> Back to the Agenda</Link>
            </UseLang>
            <UseLang pt_br>
                <Link to='../pt-br/programa/agenda' className='go_back_agenda'><i className='icon-arrow-left'></i> Voltar para a agenda</Link>
            </UseLang> 
        </>
    )
}

export default GoBackAgenda
