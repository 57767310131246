import React from 'react' 
import UseLang from '../../../../../../components/hook/UseLang';
import MyComponent from '../../../../../../components/interface/my-component/MyComponent';

const PresentationTitle = props => {
    const {title, onClick, element} = props; 
    return (
        <MyComponent element={element} className={`presentation_title ${(title?.info_es?.description || title?.info_es?.description || title?.info_es?.description) ? 'active' : ''}`} onClick={(title?.info_es?.description || title?.info_es?.description || title?.info_es?.description) ? onClick : null}>
         
            <UseLang es>{title?.info_es?.title}</UseLang>
            <UseLang en>{title?.info_en?.title}</UseLang>
            <UseLang pt_br>{title?.info_pt_br?.title}</UseLang>
         </MyComponent>
    )
}

export default PresentationTitle
