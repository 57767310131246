import React from 'react'
import UseLang from '../../../components/hook/UseLang'

const AccessCode = ({code}) =>{
    return (
        <>{code ?
            <p>
                <UseLang es>Código de acceso a Zoom:</UseLang>
                <UseLang en>Zoom Access Code:</UseLang>
                <UseLang pt_br>Código de Acesso Zoom:</UseLang>
            </p> : null}
        </>
    )
}

export default AccessCode
