import React from 'react'
import UseLang from '../../../components/hook/UseLang'

function JoinActivity() {
    return (
        <h3>
            <UseLang es>Participa de la actividad usando este link</UseLang>
            <UseLang en>Please join the activity using this link</UseLang>
            <UseLang pt_br>Participe da atividade acessando este link</UseLang>
        </h3>
    )
}

export default JoinActivity
