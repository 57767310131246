import React from 'react'  
import FormatSlide from './FormatSlide' 
import RatesSlide from './RatesSlide'
import RegistrationSlide from './RegistrationSlide'
import InfoSlide from './InfoSlide';

const FeeSlide = props => {
    const { title, info, price, discount, discount_price, discount_info, registration_url, id, more_info, modal_window, deadline, modal_state } = props
 
    return (
        <>
            <div className={`fee_content fee_slide_${id}`}>
                <RatesSlide price={price} discount={discount} discount_price={discount_price} discount_info={discount_info} deadline={deadline} />
                <FormatSlide title={title} info={info}/>
                <RegistrationSlide more_info={more_info} registration_url={registration_url} modal_window={modal_window} modal_state={modal_state} /> 
            </div>
            {more_info && <InfoSlide more_info={more_info}  />}
             
        </>
    )
}

export default FeeSlide
