import React from 'react'
import PresentationTime from './PresentationTime';
import PresentationTitle from './PresentationTitle';
import Speakers from '../presentation-modal/Speakers';
import DynamicMeetingBoard from '../presentation-modal/DynamicMeetingBoard';
import PresentationButton from './PresentationButton';
import Slides from '../presentation-modal/Slides';
import Videos from '../presentation-modal/Videos';
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationItem = props => {
    const { item, onClick, presentation_id } = props;

    return (
        <>
            <UseLang es>{/* Muestra PresentationItem si info_es.title existe */}
                {item?.info_es?.title && (
                    <PresentationData item={item} onClick={onClick} presentation_id={presentation_id} />
                )}
            </UseLang>
            <UseLang en>
                {/* Muestra PresentationItem si info_en.title existe */}
                {item?.info_en?.title && (
                    <PresentationData item={item} onClick={onClick} presentation_id={presentation_id} />
                )}
            </UseLang>
            <UseLang pt_br>
                {/* Muestra PresentationItem si info_pt_br.title existe */}
                {item?.info_pt_br?.title && (
                    <PresentationData item={item} onClick={onClick} presentation_id={presentation_id}/>
                )}
            </UseLang>
        </>
    )
}

export default PresentationItem

const PresentationData = (props) => {
    const { onClick, item, presentation_id } = props;
    return (
        <div className='presentation_item'>
            <PresentationTime start_time={item?.start_time} ending_time={item?.ending_time} />
            <PresentationTitle title={item} onClick={onClick} element='button' />
            <DynamicMeetingBoard start_time={item?.start_time} dynamic={item?.dynamic} format={item?.format} room={item?.room} interpretation={item?.interpretation} languaje={item?.languaje} live_streaming={item?.live_streaming} streaming_url={item?.streaming_url} />
            <Speakers presentation_id={presentation_id} instructor={item?.instructor} speakers={item?.speakers} moderator={item?.moderator} modal={true} />
            <Slides presentation={item?.presentation} title_status={true} />
            <Videos videos_es={item?.videos} videos_en={item?.videos_en} videos_pt_br={item?.videos_pt_br} />
            {
                (item?.info_es?.description || item?.info_en?.description || item?.info_pt_br?.description) &&
                <PresentationButton item={item} onClick={onClick} />}
        </div>

    )
}