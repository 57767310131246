import React, {useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './DayPicker.css';
import { LangContext } from '../../../../context/DataContext';
import { parse } from 'date-fns';
import UseLang from '../../../../components/hook/UseLang';


const DayPicker = ({ agendaData }) => {
    const {myLang} = useContext(LangContext)
  const result = agendaData.reduce((acc, item) => {
    const selectedDate = item?.acf?.start_time.split(" ")[0]; // Obtenemos solo la fecha 
    if (!acc.includes(selectedDate)) {
      acc.push(selectedDate);
    }
    return acc;
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search); // Busco la url params
  const selectedDate = urlSearchParams.get('day'); // Obtengo el params que requiero
  const navigate = useNavigate();

  const getLocalizedDay = (dateString) => {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    let options = { weekday: 'long' };
  
    if (myLang === 'es') {
      return capitalize(parsedDate.toLocaleDateString('es-ES', options));
    } else if (myLang === 'pt-br') {
      return capitalize(parsedDate.toLocaleDateString('pt-BR', options));
    } else {
      return capitalize(parsedDate.toLocaleDateString('en-US', options));
    }
  };
  
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  

  const changeDay = (desactive, day) => {
    if (day) {
      const queryParams = new URLSearchParams();
      queryParams.set('day', day);
      const getDayUrl = `?${queryParams.toString()}`;
      navigate(getDayUrl.replace(/%2F/g, `/`)); 
        setTimeout(() => {
      const scrollTarget = document.getElementById('DayPicker');
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Puedes ajustar el tiempo de espera según tus necesidades
        
        
    }
    if (desactive) {
      navigate(window.location.pathname);
    }
  }
   
  // Función 
  return (
    <>
      {result.length > 1 ? (
        <article>
          <div className='day_picker' id="DayPicker">
            <h2>
              <UseLang es>Seleccionar día</UseLang>
              <UseLang en> Select day</UseLang>
              <UseLang pt_br>Selecione o dia</UseLang>  
            </h2>
            <ul>
              {result?.sort((a, b) => new Date(a) - new Date(b))?.map((item, id) => (
                 
                <li
                  key={id}
                  className={`day ${item === selectedDate ? 'active' : ''}`}
                  onClick={() => changeDay(item === selectedDate, item)}
                >
                  {getLocalizedDay(item)} {item === selectedDate ? <i className='icon-xmark' /> : null}
                </li>
              ))}
            </ul>
          </div>
        </article>
      ) : null}
    </>
  );
};

export default DayPicker;
