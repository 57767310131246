import React from 'react'
import slugify from 'react-slugify';
import UseLang from '../../../../components/hook/UseLang';
import { Link } from 'react-router-dom'; 
import UseTitleHTML from '../../../../components/hook/UseTitleHTML';

const SpeakersList = props => {
    const {title, bios}= props;
    return (
        <article className='speakers'>
            <div>
                <UseTitleHTML html={title} /> 
                <div className='speakers_list'>
                    {bios?.sort((a, b) => ( a.title.rendered > b.title.rendered ? 1 : -1 ))?.map((item, id) =>{
                        const { title, acf } = item
                        return (
                        <div key={id}>
                            {
                                acf.picture_default === false ? <img src={acf.photo} className='photo' alt={title}/>
                                
                                : <>
                                    {acf.sex === false ? <img src='/default-man.png' className='photo' alt={title}/> 
                                    : <img src='/default-woman.png' className='photo' alt={title}/>}
                                </>
                            }
                            {
                                acf.biografia_pendiente === false ? <>
                                <UseLang es>
                                    <Link to={`/es/programa/oradores/${slugify(title?.rendered)}`}>{title?.rendered}</Link> 
                                </UseLang>
                                <UseLang en>
                                    <Link to={`/en/programme/speakers/${slugify(title?.rendered)}`}>{title?.rendered}</Link> 
                                </UseLang>
                                <UseLang pt_br>
                                    <Link to={`/pt-br/programa/palestrantes/${slugify(title?.rendered)}`}>{title?.rendered}</Link> 
                                </UseLang>
                                </>: <p dangerouslySetInnerHTML={{ __html: title?.rendered }} />
                                
                            }
                            
                            
                        </div>
                        )
                    })}
                </div>
            </div>
        </article>
    )
}

export default SpeakersList
