import React from 'react'
import './alert.css'

const Alert = props => {
    const { blue, red, children } = props
    
    const background_color = `${
        blue ? 'blue' : '' 
        || 
        red ? 'red' : ''
    }`
     
    return (
        <div className={`alert ${background_color}`}>
            {children}
        </div>
    )
}

export default Alert
