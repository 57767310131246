import React, { useState, useEffect, useRef } from 'react' 
import PhotoButton from '../photos/PhotoButton';
import Photos from '../photos/Photos'; 
/* import StandButton from '../stand/StandButton';
import NetworkingButton from './NetworkingApp';
import RegistrationApp from './RegistrationApp';
import LiveStreamingApp from './LiveStreamingApp'; */

const Apps = props => {
  const [app, setApp] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setApp(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);

  const handleApp = () => {
    setApp(!app);
  }

  const handleButtonClick = (event) => {
    event.stopPropagation();
    handleApp();
  }
 

  return (
    <>
    <li className="select_apps" ref={ref}>
      {!app ?<button className="button_apps" onClick={handleButtonClick}>
        <i className="icon-grid-circle"></i>
      </button> : <button className="button_apps" onClick={handleButtonClick}>
        <i className="icon-xmark"></i>
      </button>}
      {app ? 
        <div className="event_apps">
          <div>
            {/* 
            
             
            <a href="https://discord.gg/YSf6UUx3yY" target='_blank' rel="noreferrer"><i className="icon-discord-1"></i> Discord</a>
               */}
            <PhotoButton  />  
            {/* <a href="https://rir.la/lacnic40" target='_blank' rel="noreferrer"><i className="icon-video-call"></i> Zoom</a>
            <LiveStreamingApp />
            <NetworkingButton/>   
            <RegistrationApp/>
             <StandButton  /> */}
          </div>
        </div>
        : undefined
      }
    </li>
    <Photos />
    </>
  );
}

export default Apps;
