import React from 'react'

const EmptySlot = item => {
    if(item === 'empty'){
    return (
        <>&nbsp;</>
    )}
}

export default EmptySlot
