import React from "react";
import UseHTML from "../../../components/hook/UseHTML";
import UseTitleHTML from "../../../components/hook/UseTitleHTML";
// import sponsorshipLacnicEventVideo from './sponsors-lacnic39.mp4'

const SponsorshipHeader = (props) => {
  const { title, content } = props;

  return (
    <div className="sponsorship_header">
      <video width="1000" height="240" className="sponsorship_video" autoPlay loop muted>
        {/* <source src={sponsorshipLacnicEventVideo} type="video/mp4" ></source> */}
      </video>
      <div className="sponsorship_header_content">
        <div className="sponsorship_header_inner">
          <div>
            <UseTitleHTML html={title} />
            <UseHTML html={content} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SponsorshipHeader;
