import React, { useRef, useState, useEffect} from 'react'; 
import UseHTML from '../../hook/UseHTML';
import './accordion.css'

const AccordionItem = ({  handleToggle, active, label, id, content }) => {  

    const [height, setHeight] = useState(0)
    const accordionEl = useRef()

    useEffect(() => {
        setHeight(accordionEl.current.scrollHeight)
    },[height])
 
      
    return (
         <div className='accordion_container'>
            <div className='accordion_header'>
                 <div className={`accordion_toggle  ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>  
                    <h5 className='accordion_title'><UseHTML html={label}/></h5>
                    <i className='accordion_icon icon-angle-down'></i>
                </div>
            </div>
            <div ref={accordionEl} className={`collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? {height: `${accordionEl.current.scrollHeight}px`}
                    : { height: "0px" }
            }>

                <div className='accordion_content'>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
        </div> 
    )
}

export default AccordionItem
