import React from 'react'; 
import UseLang from '../../hook/UseLang';

function FooterLegal() { 
    return (
        <ul className='foot_legal'>
            <UseLang es>
                <li><a href="https://www.lacnic.net/4038/1/lacnic/codigo-de-conducta-de-la-comunidad-de-lacnic">Código de Conducta</a></li>
                <li><a href="https://www.lacnic.net/2923/1/lacnic/politica-de-privacidad">Política de privacidad</a></li>
            </UseLang>
            <UseLang en>
                <li><a href="https://www.lacnic.net/4039/2/lacnic/lacnic-community-code-of-conduct">Code of Conduct</a></li>
                <li><a href="https://www.lacnic.net/2924/2/lacnic/privacy-policy">Privacy Policy</a></li>
            </UseLang>
            <UseLang pt_br>
                <li><a href="https://www.lacnic.net/4040/3/lacnic/codigo-de-conduta-da-comunidade-do-lacnic">Código de Conduta</a></li>
                <li><a href="https://www.lacnic.net/2925/3/lacnic/politica-de-privacidade">Política de privacidade</a></li>
            </UseLang> 
        </ul>
    )
}

export default FooterLegal
