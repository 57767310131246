import React from 'react'  
import UseTitleHTML from '../../../components/hook/UseTitleHTML';


const HotelsHeader = props => {
    const { title } = props;
    return (
        <article className='hotels_header'>
            <div>
                <UseTitleHTML html={title} />
            </div>
        </article>
    )
}

export default HotelsHeader
