import React from 'react';
import './HeadSfuff.css'
import LangButton from './LangButton';
import Apps from './apps/Apps';

const HeadStuff = props => {

    const { onClick } = props
    return (
        <nav className='nav_headstuff' onClick={onClick}>
            <ul>
                <Apps />  
                <LangButton {...props} /> 
            </ul>
        </nav>
    )
}

export default HeadStuff
