import React from 'react'
import MenuButtonItem from '../mainNavMenu/MenuButtonItem'
import FetchDropdownLinks from '../mainNavMenu/FetchDropdownLinks' 
import UseLang from '../../../hook/UseLang'; 
import { Link } from 'react-router-dom';

const ItemsMenuMedia = props => {
    
    const {item_name, item_category, url_api, id_menu, menuLang, eventID} = props;
    return (
        <ul>
            <li className='media_menu_items'>
                <MenuButtonItem item_name={item_name} /> 
                <ul> 
                    <FetchDropdownLinks
                        url_api={url_api}
                        id_menu={id_menu}
                        menuLang={menuLang}
                        eventID={eventID}
                        item_name={item_name}
                        item_category={item_category} 
                    /> 
                </ul> 
                {
                item_category === 'media' ? 
                <>
               {/*  <li> 
                    <Link className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/es/streaming'><UseLang es>Transmisión en vivo</UseLang></Link>
                    <Link className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/en/streaming'><UseLang en>Live streaming</UseLang></Link>
                    <Link className='mainNavMenu_dropdownBox_link' href='https://lacnic40.lacnic.net/pt-br/streaming'><UseLang pt_br>Transmissão ao vivo</UseLang> </Link>
                </li>
                <li> 
                    <a className='mainNavMenu_dropdownBox_link' href='https://rir.la/lacnic40' target='_blank'  rel="noreferrer"> 
                        <UseLang es>Zoom Eventos</UseLang>
                        <UseLang en>Events Zoom</UseLang>
                        <UseLang pt_br>Zoom Eventos</UseLang> 
                    </a>
                </li> */}
                <li> 
                    <Link className='mainNavMenu_dropdownBox_link' to='?multimedia=photos'> 
                        <UseLang es>Fotos</UseLang>
                        <UseLang en>Photos</UseLang>
                        <UseLang pt_br>Fotos</UseLang> 
                    </Link>
                </li>
                </> : null
            }
            </li>
        </ul>
    )
}

export default ItemsMenuMedia