import React from 'react';
import { Link } from 'react-router-dom'; 
import LogoWhite from '../../design/logo/LogoWhite';
import './Logo.css'

function EventLogo() {
    
    return (
        <Link to="/">
            <figure className={`header_logo`}>
               <LogoWhite />
            </figure>
        </Link>
    )
}  

export default EventLogo
 