import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang'; 
import Room from './Room';
import LiveStreamingButton from './LiveStreamingButton'; 

const DynamicMeetingBoard = props => {
    const { dynamic, room, interpretation, languaje, live_streaming, format, element, streaming_url, start_time } = props;
   
    // Mostrar DynamicMeetingBoard cuando todavía no pasó el día de la presentación
    const parts = start_time ? start_time.split(' ') : [];
    const date = parts[0] || '';
    const [day, month, year] = date.split('/'); 
    const pptDate = `${year}/${month}/${day}`; 
    const today = new Date(); 
    const yearToday = today.getFullYear();
    const monthToday = today.getMonth() + 1; // Sumar 1 para obtener un mes en formato convencional
    const dayToday = '0'+today.getDate();
    const pptToday = `${yearToday}/${monthToday}/${dayToday}`;  
 
    return (
    <>
    { ( pptDate === pptToday || pptDate > pptToday) ? <>
        {dynamic ?
        <div className='dynamic_meeting_board'>
            {format !== '' ? <p className='format'>
                <>
                    {format === 'in-person' ? <>
                        <i className='icon-users'></i>
                        <i className='icon-video-slash'></i>
                        <span className='label'>
                            <UseLang es>Presencial</UseLang>
                            <UseLang en>In-person</UseLang>
                            <UseLang pt_br>Presencial</UseLang>
                        </span>
                    </>
                        : undefined
                    }
                    {format === 'online' ? <>
                        <i className='icon-users-slash'></i>
                        <i className='icon-video'></i>
                        <span className='label'> Online</span>
                    </>
                        : undefined
                    }
                    {format === 'in-person-and-online' ? <>
                        <i className='icon-users'></i>
                        <i className='icon-video'></i>
                        <span className='label'>
                            <UseLang es>Presencial/Online</UseLang>
                            <UseLang en>In-person/Online</UseLang>
                            <UseLang pt_br>Presencial/Online</UseLang>
                        </span>
                    </>
                        : undefined
                    }

                </>

            </p>

                : undefined}
            <Room room={room} />
            {interpretation[0] ? <p className='presentation_interpretation'>
                <i className='icon-file-audio-1'></i>
                <span className='label'>
                    {interpretation[0] === 'simultaneous' ?
                        <><UseLang es>Interpretación simultanea</UseLang>
                            <UseLang en>Simultaneous interpretation</UseLang>
                            <UseLang pt_br>Interpretação simultânea</UseLang>
                        </> : interpretation[0]
                    }

                </span>
                {interpretation[1] ? <><span className='label'>{interpretation[1]}</span></> : null}
                {interpretation[2] ? <><span className='label'>{interpretation[2]}</span></> : null}
            </p> : undefined}
            {languaje[0] ? <p className='presentation_languaje'>
                <i className='icon-language'></i>
                <span className='label'>{languaje[0]}</span>
                {languaje[1] ? <><span className='label'>{languaje[1]}</span></> : null}
                {languaje[2] ? <><span className='label'>{languaje[2]}</span></> : null}
            </p> : undefined}
            <LiveStreamingButton streaming_url={streaming_url} live_streaming={live_streaming} element={element} />
        </div>
        : undefined}</>: null}</>)
   
      
}

export default DynamicMeetingBoard


