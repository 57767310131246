import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UseLang from '../../../components/hook/UseLang';

const Frames = (props) => {
    const { content, header, image } = props;
    return ( 
        <div className='Frames'>
            <figure><img src={`${image}`} alt={header} /></figure>
            <div>
                <h2>{header}</h2>
                <UseHTML html={content} />
                <div className='badge_buttons'> 
                    <a href="https://bit.ly/BoothLogoL40" target="_blank" rel="noreferrer" className='button blue'>
                        <UseLang es>Marcos </UseLang>
                        <UseLang en>Frames </UseLang>
                        <UseLang pt_br>Marcos </UseLang>
                    </a>
                </div>
            </div>
        </div>  
    )
}

export default Frames

 