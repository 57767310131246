import React from 'react';
import DevelopingTheAgenda from '../agenda-sessions/developing-agenda/DevelopingTheAgenda';
import UseLang from '../../../../components/hook/UseLang';

const Beer = ({event}) => {  
    return (
        <> 
            <div className='SocialBear'> 
                {event === 'gear' && <> 
                    <h1>Beer & Gear</h1>
                    <DevelopingTheAgenda agenda_state={false}/>
                    <p className="presentation_start_time">03/10/2023, 18:00 - 20:00  (Fortaleza)</p>
                     
                    <UseLang es>
                        <p>Esta actividad de networking se realizar&aacute; en el &aacute;rea de exposici&oacute;n comercial, en el primer piso del centro de eventos F&aacute;brica de Negocios.&nbsp;</p>
                        <p>El encuentro, patrocinado por Angola Cables, permitir&aacute; a los participantes visitar los stands de los patrocinadores, mientras disfrutan de unas cervezas. </p>
                    </UseLang>
                    <UseLang en>
                        <p>This networking activity takes place in the commercial exhibition area, on the first floor of the F&aacute;brica de Negocios event center.</p>
                        <p>This event, sponsored by Angola Cables, will allow participants to visit the sponsors' booths while enjoying a few beers, without the pressure of time.</p>
                    </UseLang>
                    <UseLang pt_br>
                        <p>Esta atividade de networking acontece na &aacute;rea de exposi&ccedil;&atilde;o comercial, no primeiro andar do centro de eventos F&aacute;brica de Neg&oacute;cios.</p>
                        <p>O encontro, patrocinado pela Angola Cables, permitir&aacute; aos participantes visitar os estandes dos patrocinadores, enquanto saboreiam algumas cervejas.&nbsp;</p>
                    </UseLang>
                    <img src="https://apievt.lacnic.net/wp-content/uploads/2023/09/angolacables.png" alt="Angola Cables" className="BearSponsor" />
                </>}
                {event === 'peer' && <> 
                    <h1>Beer & Peer</h1>
                    <p className="presentation_start_time">05/10/2023, 18:30 - 20:00 (Fortaleza)</p>
                    <UseLang es> 
                        <p>El Beer &amp; Peer es una actividad organizada por LAC-IX y LACNOG y consiste en un espacio dedicado especialmente a Coordinadores de Peering. La participaci&oacute;n en dicho encuentro requiere registro previo en <a href="http://peeringforum.lat/2023-fortaleza/" target="_blank" rel="noreferrer">http://peeringforum.lat/2023-fortaleza/</a> y estar&aacute; limitado a aquellos representantes que desempe&ntilde;en funciones de Peering en las empresas u organizaciones a las que pertenecen</p>
                    </UseLang>
                    <UseLang en> 
                        <p>Beer &amp; Peer is an activity organized by LAC-IX and LACNOG, a space especially reserved for Peering Coordinators. If you would like to participate, you must first register at <a href="http://peeringforum.lat/2023-fortaleza/" target="_blank" rel="noreferrer">http://peeringforum.lat/2023-fortaleza/</a>. Please note that participation in this meeting will be limited to representatives who perform peering functions in their companies or organizations.</p>
                    </UseLang>
                    <UseLang pt_br> 
                        <p>O Beer &amp; Peer &eacute; uma atividade organizada por LAC-IX e LACNOG e consiste em um espa&ccedil;o dedicado principalmente para Coordenadores de Peering. A participa&ccedil;&atilde;o neste encontro requer de inscri&ccedil;&atilde;o pr&eacute;via em&nbsp;<a href="http://peeringforum.lat/2023-fortaleza/" target="_blank" rel="noreferrer">http://peeringforum.lat/2023-fortaleza/</a>&nbsp;e estar&aacute; limitada aos representantes que desempenhem fun&ccedil;&otilde;es de Peering nas empresas ou organiza&ccedil;&otilde;es a que pertencem</p>                    
                    </UseLang>
                     
                </>}   
            </div>
        </>
    )
}

export default Beer
