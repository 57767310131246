import React from 'react'
import UseTitleHTML from '../../../components/hook/UseTitleHTML'
import UseHTML from '../../../components/hook/UseHTML'

const VisasHeader = props => {
    const { title, content } = props;
    return (
        <article className='visas_header'>
            <div>
                <UseTitleHTML html={title} />
                <UseHTML html={content} />
            </div>
        </article>
    )
}

export default VisasHeader
