import React from 'react'

const UseTitleHTML = props => {
    const { children, html, className, onClick } = props;
     
    return (
        <>
           {className ? 
           <>
            <h1 className={`${className}`} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
            {children} </>: 
            <>
            <h1 onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
            {children}
            </>}
        </>
    )
}

export default UseTitleHTML;
