import React from 'react'
import UseLang from '../../../components/hook/UseLang'

function InfoAboutRegistration() {
    return (
        <>
            <UseLang es>
                <h2>Información importante sobre registro LACNIC 39</h2>
                <p>Debido a que esperamos una gran concurrencia de participantes, <strong>n</strong><strong>o habr&aacute; registro in situ </strong>en el evento LACNIC 39.</p>
                <p>Todos aquellos que participen de forma presencial, deber&aacute;n tener completo y aprobado su registro online.</p>
                <p>Para registrarse, ingresar  <a href="https://eventos.lacnic.net/ev4/detail?id=lacnic39" target="_blank" rel="noreferrer">aquí </a> </p>
            </UseLang>
            <UseLang en>
                <h2>Important information about LACNIC 39 registration</h2>
                <p>Given the large number of participants we expect, <strong>on-site registration will <u>not</u> be available </strong>in LACNIC 39 event.</p>
                <p>All those who participate in person, must have completed and approved their online registration.</p>
                <p>For registration, please go to <a href="https://eventos.lacnic.net/ev4/detail?id=lacnic39" target="_blank" rel="noreferrer">here</a></p>
            </UseLang>
            <UseLang pt_br>
                <h2>Informações importantes para se inscrever no LACNIC39 </h2>
                <p>Como esperamos um grande n&uacute;mero de participantes,<strong><u> n&atilde;o</u> haver&aacute; inscri&ccedil;&otilde;es in situ </strong>no evento LACNIC 39.</p>
                <p>Todos aqueles que participarem pessoalmente devem ter conclu&iacute;do e aprovado seu registro on-line.</p>
                <p>Para se inscrever, acesse <a href="https://eventos.lacnic.net/ev4/detail?id=lacnic39" target="_blank" rel="noreferrer">aqui</a></p>
            </UseLang>
        </>
    )
}

export default InfoAboutRegistration
