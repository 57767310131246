import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import './welcome-kit.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import WelcomeKitHeader from './WelcomeKitHeader';
import DigitalBadge from './DigitalBadge';
import Filters from './Filters';
import KitSocialNetworks from './KitSocialNetworks';
import Frames from './Frames';

const WelcomeKit = props => { 
    const { handleID, handleApiURL, handleLoading, data  } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 

    let contents = [];
    if (Array.isArray(data?.acf?.create_accordion)) {
      contents = data.acf.create_accordion.map((info, index) => ({
        acf_fc_layout: info.acf_fc_layout,
        id: info.id,
        label: info.label,
        content: info.content,
        image: info.image,
        state: info.state,
        key: index,
      }));
    }  
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='1401'>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/how-to-participate/welcome-kit' url_es='/es/como-participar/kit-de-bienvenida' url_pt_br='/pt-br/como-participar/kit-de-boas-vindas' {...props} />
                <Main className='container welcome_kit' loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider />
                            <StickyHeader/>
                            <> {contents.length > 0 ? (
                            <article> 
                                <div> 
                                    <WelcomeKitHeader title={data?.title?.rendered} />
                                    <div className='welcome_kit_tools'> 
                                        <DigitalBadge header={contents[0].label} content={contents[0].content} image={contents[0].image} />
                                        <Filters header={contents[1].label} content={contents[1].content} image={contents[1].image} />
                                        <Frames header={contents[3].label} content={contents[3].content} image={contents[3].image} />
                                    </div>
                                    <KitSocialNetworks header={contents[2].label} content={contents[2].content} image={contents[2].image} />
                                </div>
                            </article>)
                             : null} </>
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default WelcomeKit
