import React from 'react'
import Charging from './Charging'
import './spin.css'

function Spin() {
    return (
         
        <div>
            <div className="spin">
                <div></div>
                <Charging />
            </div> 
        </div>
             
    )
}

export default Spin
