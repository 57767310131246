import React, { useContext, useState } from 'react'; 
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost'
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import Main from '../../../components/interface/main/Main'; 
import './how-to-participate.css'
import ParticipateGrid from './ParticipateGrid';    
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const HowToParticipate = props => { 
    const { handleApiURL, handleID, handleLoading, data } = useContext(PostContext)  
    const [loading, setLoading] = useState(true);  
    
    return (
        <> 
           <UseLangLocation>
                <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='2056'>
                    <Header  title={data?.title?.rendered} loading={loading} url_es='/es/como-participar/como-participar-de-lacnic-40' url_pt_br='/pt-br/como-participar/como-participar-do-lacnic-40' url_en='/en/how-to-participate/how-to-participate-in-lacnic-40' {...props} /> 
                    <Main  className='container howToParticipate' status={data?.status} loading={loading}>
                        <section> 
                            <UseTimeOut timeout='200' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                                <SponsorsSlider/>
                                <StickyHeader/>
                                <ParticipateGrid apps={data?.acf?.apps} title={data?.title?.rendered} data={data} />
                            </UseTimeOut>
                        </section>
                    </Main>  
                    <OrganizersElement/>
                    <Footer className="master_foot foot_home" />
                </UsePost>
            </UseLangLocation> 
        </>
    )
}

export default HowToParticipate
