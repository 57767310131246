import React, { useContext,  useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header'; 
import UsePost from '../../../components/hook/UsePost'; 
import InfoCover from './InfoCover';
import InfoAccordion from './InfoAccordion';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './info.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Info = props => { 
    const { handleID, data, handleApiURL, handleLoading } = useContext(PostContext);
    const [loading, setLoading] = useState(true); 

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='2063' >
                <Header title={data?.title?.rendered} url_en='/en/hotels-and-travel/general-information' url_pt_br='/pt-br/hoteis-e-viagem/informacoes-gerais' url_es='/es/hoteles-y-viaje/informacion-general' loading={loading} {...props} />
                <Main status={data?.status} className='container info_general' loading={loading}  >
                    <section>
                        <UseTimeOut timeout='200' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <StickyHeader />
                            <InfoCover loading={loading} title={data?.title?.rendered} content={data?.content?.rendered} />
                            <SponsorsSlider/> 
                            <InfoAccordion accordion={data?.acf?.create_accordion} />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Info
