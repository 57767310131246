import React from 'react'
import UseLang from '../../../components/hook/UseLang';

const RegistrationButton = props => {
    const { openNewsForm, registration_url, /* more_info */ modal_state } = props;
    return (
        <>
            {!modal_state ?

                <button className='button' onClick={openNewsForm}>
                    <RegistrationText />
                </button>
                :
                <a className='button' href={`${registration_url}`} target="_blank" rel="noreferrer">
                    <RegistrationText />
                </a>
            }</>
    )
}

export default RegistrationButton

function RegistrationText() {
    return (
        <>
            <UseLang es>Registrarse</UseLang>
            <UseLang pt_br>Registro</UseLang>
            <UseLang en>Registration</UseLang>
        </>
    )
}
