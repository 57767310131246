import React, { useContext, useEffect } from 'react';
import { LangContext, SessionContext } from '../../../../context/DataContext';
import { useNavigate } from 'react-router-dom';

const TableElement = (props) => {
  const { event_id } = props;
  const { myLang } = useContext(LangContext);
  const { handleLang, handleEvent, session_slug} = useContext(SessionContext);

  useEffect(() => {
    handleLang(myLang);
    handleEvent(event_id);
  }, [myLang, handleLang, handleEvent, event_id]);

  return <TableElementWrapper session_slug={session_slug} event_id={event_id} {...props} />;
};

 

const TableElementWrapper = (props) => { 
  const { myLang } = useContext(LangContext);
  const {  
    type,
    children,
    colspan,
    rowspan,
    name,
    item,
    day,
    width,
    social 
  } = props;

  const th_styles = {
    width: width,
  };
 
  let destinationURL;
 
if (name === 'DNS') {
  if (myLang === 'es') {
    destinationURL = 'espacio-dns-en-lac';
  } else if (myLang === 'en') {
    destinationURL = 'dns-space-at-lac';
  } else if (myLang === 'pt-br') {
    destinationURL = 'espaco-dns-na-lac';
  } else {
    destinationURL = 'espacio-dns-en-lac'; // Valor por defecto
  }
} else if (name === 'First Conf.' || name === 'First Training') {
  destinationURL = 'first';
} else if (name === 'Tutoriales') {
  if (myLang === 'es') {
    destinationURL = 'tutoriales';
  } else if (myLang === 'en') {
    destinationURL = 'tutorials';
  } else if (myLang === 'pt-br') {
    destinationURL = 'tutoriais';
  } else {
    destinationURL = 'turoriales'; // Valor por defecto
  }
} else if (name === 'LAC CSIRTs') {
  if (myLang === 'es') {
    destinationURL = 'reunion-de-lac-csirts';
  } else if (myLang === 'en') {
    destinationURL = 'csirts-meeting';
  } else if (myLang === 'pt-br') {
    destinationURL = 'reuniao-lac-csirts';
  } else {
    destinationURL = 'reunion-de-lac-csirts'; // Valor por defecto
  }
} else if (name === 'LACNIC' || name === 'Apertura' || name === 'Plenaria' || name === 'WorkingGroup' || name === 'Cierre') {
  if (myLang === 'es') {
    destinationURL = 'plenaria';
  } else if (myLang === 'en') {
    destinationURL = 'plenary';
  } else if (myLang === 'pt-br') {
    destinationURL = 'plenario';
  } else {
    destinationURL = 'plenario'; // Valor por defecto
  }
} else if (name === 'Nuevos participantes') {
  if (myLang === 'es') {
    destinationURL = 'orientacion-para-nuevos-participantes';
  } else if (myLang === 'en') {
    destinationURL = 'session-for-new-participants';
  } else if (myLang === 'pt-br') {
    destinationURL = 'sessao-para-novos-participantes';
  } else {
    destinationURL = 'orientacion-para-nuevos-participantes'; // Valor por defecto
  }
} else if (name === 'LACNOG') {
  destinationURL = 'lacnog';
} else if (name === 'Capture the Flag') {
  destinationURL = 'capture-the-flag';
} else if (name === 'Foro Público') {
  if (myLang === 'es') {
    destinationURL = 'foro-publico-de-politicas';
  } else if (myLang === 'en') {
    destinationURL = 'public-policy-forum';
  } else if (myLang === 'pt-br') {
    destinationURL = 'forum-publico-de-politicas';
  } else {
    destinationURL = 'foro-publico-de-politicas'; // Valor por defecto
  }
} else {
  destinationURL = null; // Manejar otros casos o valores por defecto aquí
}

const navigate = useNavigate()
  const selectSession = () => {
    if (name && name !== 'Registro' && name !== 'Lunch' && name !== 'Break') {
      if (day) {
        const queryParams = new URLSearchParams();
        queryParams.set('day', day);
         
        const trimmedDestinationURL = destinationURL.trim();
        const fullURL = `${trimmedDestinationURL}?${queryParams.toString()}`; 
 
        navigate(fullURL.replace(/%2F/g, "/"));
      } else {
        
        const fullURL = `agenda/${destinationURL}`;
        window.open(fullURL, '_self');
      }
    } else {
      return null;
    }
  };
  if(null){
    selectSession();//eliminar luego
  }
   

  const Bkgds = `${ name === 'Capture the Flag'
  ? 'GreenBkgd mouse_enter':
  name === 'DNS'
    ? 'OrangeBkgd mouse_enter'
    : name === 'Registro'
      ? 'BrownBkgd WhiteTxt'
      : '' || name === 'Tutoriales'
        ? 'YellowBkgd mouse_enter'
        : '' || name === 'BoF'
          ? 'OrangeBkgd mouse_enter'
          : '' || name === 'Apertura'
            ? 'BlueBkgd mouse_enter'
            : '' || name === 'Nuevos participantes'
              ? 'BlueBkgd mouse_enter'
              : '' || name === 'Plenaria'
                ? 'BlueBkgd mouse_enter'
                : '' || name === 'Foro Técnico'
                  ? 'BlueBkgd mouse_enter'
                  : '' || name === 'Foro Público'
                    ? 'BlueBkgd mouse_enter'
                    : '' || name === 'Reunión Becados'
                      ? 'BlueBkgd mouse_enter'
                      : '' || name === 'Asamblea General'
                        ? 'BlueBkgd mouse_enter'
                        : '' || name === 'LACNIC'
                        ? 'BlueBkgd mouse_enter'
                        : '' || name === 'Cierre'
                          ? 'BlueBkgd mouse_enter'
                          : '' || name === 'LACNOG'
                              ? 'BlueBkgd mouse_enter'
                              : '' || name === 'WorkingGroup'
                            ? 'BlueBkgd mouse_enter'
                            : '' || name === 'Internet en México, presente y futuro'
                              ? 'BlueBkgd mouse_enter'
                              : '' || name === 'LAC PF'
                                ? 'OrangeBkgd mouse_enter'
                                : '' || name === 'RISE Conf.'
                                  ? 'GreenBkgd mouse_enter'
                                  : '' || name === 'RISE Training'
                                    ? 'GreenBkgd mouse_enter'
                                    : '' || name === 'First Conf.'
                                    ? 'GreenBkgd mouse_enter'
                                    : '' || name === 'First Training'
                                    ? 'GreenBkgd mouse_enter'
                                    : '' || name === 'LAC CSIRTs'
                                      ? 'GreenBkgd mouse_enter'
                                      : '' || name === 'Break'
                                        ? 'BlackBkgd mouse_enter'
                                        : '' || name === 'Lunch'
                                          ? 'BlackBkgd mouse_enter'
                                          : '' || name === 'Cóctel de Bienvenida'
                                            ? 'VioletBkgd mouse_enter'
                                            : '' || name === 'Evento Social'
                                              ? 'VioletBkgd mouse_enter'
                                              : '' || name === 'LACNIC Runners'
                                                ? 'VioletBkgd mouse_enter'
                                                  : '' || name === 'Beer&Gear'
                                                  ? 'VioletBkgd mouse_enter'
                                                    : '' || name === 'Beer&Peer'
                                                    ? 'VioletBkgd mouse_enter'
                                                : '' 
                                                
    }`;

  // Obtener la fecha seleccionada de la URL
  /* const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedDateStr = urlSearchParams.get('day'); */
  const { selectedDateStr, tableStyles} = useContext(SessionContext);
 
  if (type === 'th') {
    return (
      <>
      {selectedDateStr === null ? 
        <th colSpan={colspan} rowSpan={rowspan} className={`${item === 'header-date' ? 'HeaderDate' : ''} ${item === 'header-time' ? 'HeaderDate' : ''} ${day ? `${day}_column` : ''}`} style={th_styles}>
          {children} </th> 
        : <>
        
        {(item !== 'header-time' || tableStyles === 'StandardTable') ? 
  (selectedDateStr === day && 
    <th colSpan={colspan} rowSpan={rowspan} 
      className={`${item === 'header-date' ? 'HeaderDate' : ''} 
      ${item === 'header-time' ? 'HeaderDate' : ''} 
      ${day ? `${day}_column` : ''}`} 
      style={tableStyles === 'StandardTable' ? th_styles : undefined}>
      {children}
    </th>
  )
  : null}
        
        </>
      }</>
    );
  }
  if (type === 'td') {
    return (
        <>  
          {selectedDateStr === null ? 
          <td
            onClick={selectSession}
            colSpan={colspan}
            rowSpan={rowspan}
            className={`${item === 'activity' ? Bkgds : 'TransparentBkgd'} ${
              item === 'time' ? 'TimeZone' : ''
            }`}
          >{children}
          </td>  
          : <> {(item !== 'time' || tableStyles === 'StandardTable') ? (
            selectedDateStr === day && (
              <td
                onClick={selectSession}
                colSpan={colspan}
                rowSpan={rowspan}
                className={`${item === 'activity' ? Bkgds : 'TransparentBkgd'} ${
                  item === 'time' ? 'TimeZone' : ''
                }`}
              >
                {children}
              </td>
            )
          ) : null}
            </>
      }
      </>
    );
  }
  if (type === 'social') {
    return (
      <>{
        selectedDateStr === null ? <td
        onClick={social}
        colSpan={colspan}
        rowSpan={rowspan}
        className={`${item === 'activity' ? Bkgds : 'TransparentBkgd'} ${
          item === 'time' ? 'TimeZone' : ''
        }`}
      >
        {children}
      </td>: <>{selectedDateStr === day && <td
          onClick={social}
          colSpan={colspan}
          rowSpan={rowspan}
          className={`${item === 'activity' ? Bkgds : 'TransparentBkgd'} ${
            item === 'time' ? 'TimeZone' : ''
          }`}
        >
          {children}
        </td>}</>
      }</>
    );
  }
};

export default TableElement


