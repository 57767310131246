import React, { useState } from 'react';
import { parse, isSameDay } from 'date-fns';
import PresentationItem from './PresentationItem';
import PresentationModal from './PresentationModal';

const PresentationRow = (props) => {
  const { agendaData } = props;

  const [presentationData, setPresentationData] = useState(false);
  const handlePresentationData = (id) => {
    setPresentationData(id);
    openModalData(true);
  };

  const [showData, setShowData] = useState(false);
  const openModalData = () => setShowData(true);
  const closeModalData = () => setShowData(false);

  // Obtener la fecha seleccionada de la URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedDateStr = urlSearchParams.get('day');
  
  // Filtrar las presentaciones según la fecha seleccionada
  const filteredAgendaData = agendaData.filter((item) => {
    const startTime = parse(item?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date());
  
    if (selectedDateStr) {
      const selectedDate = parse(selectedDateStr, 'dd/MM/yyyy', new Date());
      return isSameDay(startTime, selectedDate);
    } else {
      return true;
    }
  }); 
  
  return (
    <article className='presentations'>
      <div className='presentations_list'>
        {filteredAgendaData ?  
        <>
        {filteredAgendaData
          .sort((a, b) =>
            parse(a?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date()) -
            parse(b?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date())
          )
          ?.map((item, id) => (
            <PresentationItem
              key={id}
              onClick={() => handlePresentationData(item?.id)}
              item={item?.acf}
              presentation_id={item?.id}
            />
          ))}
          </>
        : undefined}
        {agendaData ?<>{agendaData?.map((item, id) => (
          presentationData === item?.id && (
            <PresentationModal
              key={id}
              show={showData}
              onCancel={closeModalData}
              item={item?.acf}
              presentation_id={item?.id}
            />
          )
        ))}</> : undefined}
         
         
      </div>
    </article>
  );
};

export default PresentationRow;
