import React from 'react';
import EventStatistics from '../../../components/sections/statistics/EventStatistics'
import StatisticsWrapper from '../../../components/sections/statistics/StatisticsWrapper'
import UseLang from '../../../components/hook/UseLang';

function HomeStatistics() {
    return (
        <StatisticsWrapper>
            <h2>
                <UseLang es>¿Qué puedes esperar del evento?</UseLang>
                <UseLang en>What to expect:</UseLang>
                <UseLang pt_br>O que você pode esperar do evento?</UseLang>
            </h2>
            <EventStatistics />
        </StatisticsWrapper>
    )
}

export default HomeStatistics
