import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main';
import HotelsAccordion from './HotelsAccordion';
import HotelsHeader from './HotelsHeader';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './hotels.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import HotelsMap from './HotelsMap';

const Hotels = props => { 
    const { handleID,  handleApiURL, handleLoading, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
  
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='2094'>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/hotels-and-travel/hotels' url_es='/es/hoteles-y-viaje/hoteles' url_pt_br='/pt-br/hoteis-e-viagem/hoteis' {...props} />
                <Main className='container main_hotels' loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                             
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <HotelsHeader title={data?.title?.rendered} />
                            <HotelsMap content={data?.content?.rendered} />
                            <HotelsAccordion accordion={data?.acf?.create_accordion} />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Hotels
