import React from 'react'

const UseJotForm = props => {
    const {id, title, height}= props;
    return (
        <iframe
            id={`jotform-${id}`}
            title={`${title}`}
            onLoad={() => window.parent.scrollTo(0, 0)}
            allowTransparency={true}
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            src={`https://form.jotform.com/${id}`}
            frameBorder="0"
            style={{ minWidth: '100%', maxWidth: '100%', height: `${height}`, border: 'none' }}
            scrolling="no"
        />
    )
}

export default UseJotForm
