import React from 'react'
import UseLang from '../../../../components/hook/UseLang'


const Runners = props => {
   
  return (
    <>
      <div className='RunnersImage'>
        
      </div>
      <div className='social_activity_content'>
        <h1>LACNIC Runners</h1>
        <UseLang es>
          <p>Los invitamos a unirse al grupo de runners de LACNIC en Fortaleza, Brasil. En esta oportunidad, realizaremos una actividad libre de caminata o corrida por la Playa de Iracema.<br /><br />Nos encontraremos el d&iacute;a mi&eacute;rcoles 4 de octubre a las 6:30 am en el lobby del Hotel Praia Centro (Av. Monsenhor Tabosa 740). La playa se encuentra a 450 m caminando por la calle Jo&atilde;o Cordeiro hacia el norte. La caminata consiste en un recorrido de 4 km (ida y vuelta) por la peatonal de la playa hacia el este. Quienes deseen correr y hacer m&aacute;s distancia, pueden seguir por la peatonal (Av Beira Mar) que se extiende por 2 km m&aacute;s. Iniciaremos el retorno a las 7:20 volviendo por el mismo recorrido, y llegaremos al hotel alrededor de las 8:00 am.</p>
          <p>Para mantenerse informados sobre las actividades del grupo de runners, pueden inscribirse a la lista en:&nbsp;</p>
          <p><a href="https://mail.lacnic.net/mailman/listinfo/runners" target="_blank" rel="noreferrer">https://mail.lacnic.net/mailman/listinfo/runners</a></p>
        </UseLang>
        <UseLang en>
          <p>We invite you to join the group of LACNIC runners in Fortaleza, Brazil.</p>
          <p>In this opportunity, we will carry out a free activity of walking or running along Iracema Beach.&nbsp;<br /><br />We will meet on Wednesday, October 4 at 6:30 am in the lobby of the Hotel Praia Centro (Av. Monsenhor Tabosa 740). The beach is 450 m walking along Jo&atilde;o Cordeiro street to the north. The walk consists of a 4 km (round trip) route along the beach pedestrian walkway to the east. Those who want to run and do more distance can continue along the pedestrian street (Av Beira Mar) which extends for 2 more km. We will begin the return at 7:20 returning along the same route, and we will arrive at the hotel around 8:00 am.</p>
          <p>To stay informed about the activities of the group of runners, you can sign up for the list of runners at:&nbsp;</p>
          <p><a href="https://mail.lacnic.net/mailman/listinfo/runners" target="_blank" rel="noreferrer">https://mail.lacnic.net/mailman/listinfo/runners</a></p>
        </UseLang>
        <UseLang pt_br>
          <p>Convidamos voc&ecirc; a se juntar ao grupo de corredores do LACNIC em Fortaleza, Brasil. Nesta ocasi&atilde;o faremos uma caminhada ou corrida pela Praia de Iracema.<br /><br />Nos encontraremos na quarta-feira, dia 4 de outubro, &agrave;s 6h30, no lobby do Hotel Praia Centro (Av. Monsenhor Tabosa 740). A praia fica a 450 m caminhando pela Rua Jo&atilde;o Cordeiro ao norte. A caminhada consiste num percurso de 4 km (ida e volta) ao longo do passadi&ccedil;o pedonal da praia, a nascente. Quem quiser correr e fazer mais dist&acirc;ncia pode continuar pela rua pedonal (Av Beira Mar) que se estende por mais 2 km. Iniciaremos o retorno &agrave;s 7h20 retornando pelo mesmo trajeto, e chegaremos ao hotel por volta das 8h00.</p>
          <p>Para se manter informado sobre as atividades do grupo de corredores, voc&ecirc; pode se inscrever na lista em:&nbsp;</p>
          <p><a href="https://mail.lacnic.net/mailman/listinfo/runners" target="_blank" rel="noreferrer">https://mail.lacnic.net/mailman/listinfo/runners</a></p>
        </UseLang>
      </div>
    </>
  )
}

export default Runners
