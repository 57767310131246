import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang';

const Videos = props => {
    const { videos_es, videos_en, videos_pt_br } = props;
    let count_es = 0;
    let count_en = 0;
    let count_pt = 0
  
    return (
        <> 
            <UseLang es>
            {videos_es ?
                <div className='presentation_video'>
                    {videos_es.length === 1 && 
                        <a href={`${videos_es[0]?.video_url}`}>
                           <i className='icon-video'></i> Video
                        </a>
                    }
                    {videos_es.length > 1 &&
                        <>{videos_es?.map((item, id) =>
                            <a key={id}  href={`${item?.video_url}`}>
                              <i className='icon-video'></i>  {`Video (Parte `} 
                                {++count_es+')'}
                            </a>
                        )}</>
                    } 
                </div> : undefined
            }</UseLang>
            <UseLang en>{videos_en ?
                <div className='presentation_video'>
                    {videos_en.length === 1 && 
                        <a  href={`${videos_en[0]?.video_url_en}`}>
                          <i className='icon-video'></i> Video
                        </a>
                    }
                    {videos_en.length > 1 &&
                        <>{videos_en?.map((item, id) =>
                            <a key={id} href={`${item?.video_url_en}`}> 
                               <i className='icon-video'></i> {`Video (Part `} 
                                {++count_en+')'}
                            </a>
                        )}</>
                    } 
                     
                </div> : undefined
            }</UseLang>
            <UseLang pt_br>{videos_pt_br ?
                <div className='presentation_video'>
                    {videos_pt_br.length === 1 && 
                        <a href={`${videos_pt_br[0]?.video_url_pt_br}`}>
                           <i className='icon-video'></i> Video
                        </a>
                    }
                    {videos_pt_br.length > 1 &&
                        <>{videos_pt_br?.map((item, id) =>
                            <a key={id} href={`${item?.video_url_pt_br}`}> 
                               <i className='icon-video'></i> {`Video (Parte `} 
                                {++count_pt+')'}
                            </a>
                        )}</>
                    } 
                </div> : undefined 
            }</UseLang>
        </>
    )
}

export default Videos
