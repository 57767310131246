import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const KitSocialNetworks = props => {
    const { content, header } = props;
    return (
        <div className='kit_social_links'>
            <div>
                <h2>{header}</h2>
                <UseHTML html={content} />
            </div>
        </div>
    )
}

export default KitSocialNetworks
