import React, { useState, useEffect, useContext } from "react";
import SocialActivities from "../social-activities/SocialActivities";
import TableCell from "./TableCell";
import "./timetable.css";
import { SessionContext } from "../../../../context/DataContext";
import TableRow from "./TableRow";

const Timetable = (props) => {
    const { eventTime } = props;
    const [socialActivity, setSelectedSocialActivity] = useState(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const option = params.get("socialActivity");

        if (option) {
            if (option === "runners" || option === "social" || option === "cocktail" || option === "beer&gear" || option === "beer-and-gear" || option === "beer-and-peer") {
                setSelectedSocialActivity(option);
                setDisplay(true);
            } else {
                window.location.href = "/error";
            }
        }
    }, [display]);

    const handleSocial = (option) => {
        setSelectedSocialActivity(option);
        setDisplay(true);

        // Actualizar los parámetros de consulta en la URL
        const params = new URLSearchParams({
            socialActivity: option,
        });
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    };

    const closeModal = () => {
        setDisplay(false);

        // Actualizar los parámetros de consulta en la URL
        const params = new URLSearchParams(window.location.search);
        params.delete("socialActivity");
        params.delete("display");
        window.history.replaceState(null, null, window.location.href.split("?")[0]);
    };

    // Obtener la fecha seleccionada de la URL 
    const { selectedDateStr, tableStyles } = useContext(SessionContext);
     
     
    return (

        <article>
            <div className="table_bleed">
                <div className="boxed_agenda">
                    <table className={`timetable ${selectedDateStr ? tableStyles : ''}`}>
                        <tbody>
                         
                            <TableRow id="Row1" selectedDateStr={selectedDateStr}>
                                <TableCell type="th" header="off" item="header-time" width="10%" day={selectedDateStr} eventTime={eventTime} {...props} />
                                <TableCell type="th" item="header-date" width="110px" day="01/10/2023" date="1" {...props} />
                                <TableCell type="th" item="header-date" width="193px" day="02/10/2023" date="2" colspan="4" {...props} />
                                <TableCell type="th" item="header-date" width="140px" day="03/10/2023" date="3" {...props} />
                                <TableCell type="th" item="header-date" width="144px" day="04/10/2023" date="4" colspan="2" {...props} />
                                <TableCell type="th" item="header-date" width="224px" day="05/10/2023" date="5" colspan="3" {...props} />
                                <TableCell type="th" item="header-date" width="101px" day="06/10/2023" date="6" {...props} />
                            
                            </TableRow>
                             <TableRow className='Row2'>
                                <TableCell type='td' item='time'  day={selectedDateStr}  time='02/10/2022 06:30' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan="4" {...props} />
                                <TableCell type='td' item='empty'   {...props} />
                                <TableCell type='social' item='activity' social={() => handleSocial('runners')} name='LACNIC Runners' colspan="2" day="04/10/2023" start_time="06:30" end_time="07:30"  {...props} />
                                <TableCell type='td' item='empty' colspan="3" {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </TableRow>
                            <TableRow className='Row3'>
                                <TableCell type='td' item='time'  day={selectedDateStr}  time='02/10/2022 07:30' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan="4" {...props} />
                                <TableCell type='td' item='empty'   {...props} />
                                <TableCell type='td' item='empty' colspan="2"   {...props} />
                                <TableCell type='td' item='empty' colspan="3"  {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </TableRow> 
                             
                            <TableRow id="Row4" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 08:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Registro" colspan={selectedDateStr !== "" ? "4" : "1"} day="02/10/2023" start_time="08:00" end_time="08:30" {...props} />
                                <TableCell type="td" item="activity" name="Registro" day="03/10/2023" start_time="08:00" end_time="08:30" {...props} />
                                <TableCell type="td" item="activity" name="Registro" colspan={selectedDateStr !== "" ? "2" : "1"} day="04/10/2023" start_time="08:00" end_time="08:30"{...props} />
                                <TableCell type="td" item="empty" colspan={selectedDateStr !== "" ? "3" : "1"} start_time="08:00" end_time="08:30"{...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                              
                            <TableRow id="Row5" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 08:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Nuevos participantes" day="02/10/2023" start_time="08:30" end_time="09:00"colspan="4" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow>
                            <TableRow id="Row6" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 09:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" day="02/10/2023" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="DNS" day="02/10/2023" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Apertura" day="03/10/2023" start_time="09:00" end_time="11:00" {...props} />
                                <TableCell type="td" item="activity" name="LACNIC" day="04/10/2023" start_time="09:00" end_time="11:00" {...props} />
                                <TableCell type="td" item="activity" name="First Conf." day="04/10/2023" start_time="09:00" end_time="11:00" {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="05/10/2023" start_time="09:00" end_time="11:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="First Training" day="05/10/2023" start_time="09:00" end_time="11:00" {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="06/10/2023" start_time="09:00" end_time="11:00" {...props} />
                            </TableRow> 
                            <TableRow id="Row7" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 11:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="4" day="02/10/2023" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="03/10/2023" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="2" day="04/10/2023" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="3" day="05/10/2023" start_time="11:00" end_time="11:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="06/10/2023" start_time="11:00" end_time="11:30" {...props} />
                            </TableRow> 
                            <TableRow id="Row8" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 11:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" day="02/10/2023" start_time="11:30" end_time="13:00" colspan="4" {...props} />
                                <TableCell type="td" item="activity" name="LACNIC" day="03/10/2023" start_time="11:30" end_time="13:00" {...props} />
                                <TableCell type="td" item="activity" name="Foro Público" day="04/10/2023" start_time="11:30" end_time="13:00" {...props} />
                                <TableCell type="td" item="activity" name="First Conf." day="04/10/2023" start_time="11:30" end_time="13:00"  {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="05/10/2023" start_time="11:30" end_time="13:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="First Training" day="05/10/2023" start_time="11:30" end_time="13:00" {...props} />
                                <TableCell type="td" item="activity" name="WorkingGroup" day="06/10/2023" start_time="11:30" end_time="13:00" {...props} />
                            </TableRow> 
                            <TableRow id="Row9"  selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 13:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="02/10/2023" start_time="13:00" end_time="14:00" colspan="4" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="03/10/2023" start_time="13:00" end_time="14:00" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="04/10/2023" start_time="13:00" end_time="14:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Lunch" day="05/10/2023" start_time="13:00" end_time="14:00" colspan="3" {...props} />
                                <TableCell type="td" item="activity" name="Cierre" day="06/10/2023" start_time="13:00" end_time="14:00" {...props} />
                            </TableRow> 
                            <TableRow selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 14:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" day="02/10/2023" start_time="14:00" end_time="16:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LAC CSIRTs" day="02/10/2023" start_time="14:00" end_time="16:00" colspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="03/10/2023" start_time="14:00" end_time="16:00" {...props} />
                                <TableCell type="td" item="activity" name="Foro Público" day="04/10/2023" start_time="14:00" end_time="16:00" {...props} />
                                <TableCell type="td" item="activity" name="First Conf." day="04/10/2023" start_time="14:00" end_time="16:00" {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" colspan="2" day="05/10/2023" start_time="14:00" end_time="16:00" {...props} />
                                <TableCell type="td" item="activity" name="First Training" day="05/10/2023" start_time="14:00" end_time="16:00" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow>  
                            <TableRow id="Row12" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 16:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="Registro" rowspan="3" day="01/10/2023" start_time="16:00" end_time="18:00" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="4" {...props} day="02/10/2023" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" day="03/10/2023" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="2" day="04/10/2023" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="activity" name="Break" colspan="3" day="05/10/2023" start_time="16:00" end_time="16:30" {...props} />
                                <TableCell type="td" item="empty" day="06/10/2023" {...props} />
                            </TableRow> 
                            <TableRow id="Row13" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 16:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="activity" name="Tutoriales" day="02/10/2023" start_time="16:30" end_time="18:00" colspan="2" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="Capture the Flag" day="02/10/2023" start_time="16:30" end_time="18:00" colspan="2" rowspan="2"  {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="03/10/2023" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACNIC" day="04/10/2023" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="First Conf." day="04/10/2023" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="LACNOG" day="05/10/2023" start_time="16:30" end_time="18:00" colspan="2" rowspan="2" {...props} />
                                <TableCell type="td" item="activity" name="First Training" day="05/10/2023" start_time="16:30" end_time="18:00" rowspan="2" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row14" selectedDateStr={selectedDateStr}> 
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 17:00" eventTime={eventTime} {...props} />
                            </TableRow> 
                            
                            <TableRow id="Row15" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 18:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" {...props} colspan="4" />
                                <TableCell type="social" item="activity" social={() => handleSocial("beer-and-gear")} name="Beer&Gear" day="03/10/2023" start_time="18:00" end_time="20:00" rowspan="3" {...props} />
                                <TableCell type="td" item="empty" {...props} colspan="2" />
                                <TableCell type="td" item="empty" {...props} colspan="3" />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row16" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 18:30" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="social" item="activity" social={() => handleSocial("cocktail")} name="Cóctel de Bienvenida" day="02/10/2023" start_time="18:30" end_time="22:00" colspan="4" rowspan="4" {...props} />
                                 
                                <TableCell type="td" item="empty" colspan="2" {...props} />
                                <TableCell type="social" item="activity" social={() => handleSocial("beer-and-peer")} name="Beer&Peer" day="05/10/2023" start_time="18:30" end_time="20:00" colspan="3" rowspan="2" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row17" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 19:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="social" item="activity" social={() => handleSocial("social")} name="Evento Social" day="04/10/2023" start_time="19:00"  end_time="00:00"  colspan="2" rowspan="5" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row18" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 20:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" {...props} colspan="3" />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row19" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 21:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row20" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 22:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="4" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
                            <TableRow id="Row21" selectedDateStr={selectedDateStr}>
                                <TableCell type="td" item="time" day={selectedDateStr} time="02/10/2022 00:00" eventTime={eventTime} {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="4" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                                <TableCell type="td" item="empty" colspan="3" {...props} />
                                <TableCell type="td" item="empty" {...props} />
                            </TableRow> 
 
                        </tbody>
                    </table>
                </div>
               {selectedDateStr ? '' : <i className="icon-mouse-scroll-left-right icon"></i>}
                <SocialActivities display={display} closeModal={closeModal} socialActivity={socialActivity} />
            </div>
        </article>
    );
};

export default Timetable;
