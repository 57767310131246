import React from 'react'
import useUrlParams from '../../../hook/useUrlParams'
import UseLang from '../../../hook/UseLang'

const PhotoButton = props => { 
    const { handleParam } = useUrlParams('multimedia', ['photos'])

     
    return (<> 
        <button onClick={() => handleParam('photos')}>
            <i className="icon-photos"></i> 
            <UseLang es>Fotos</UseLang>
            <UseLang en>Photos</UseLang>
            <UseLang pt_br>Fotos</UseLang>
        </button>
        </>
    )
}

export default PhotoButton
