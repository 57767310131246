import React from 'react' 
import BiosTemplate from './BiosTemplate';

const BiosContent = props => {
    const { bios_data} = props;
    return (
        <article className='biography'>
            <div>
                <BiosTemplate bio={bios_data}/>
            </div>
        </article>
    )
}

export default BiosContent
