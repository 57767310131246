import React from 'react'
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const FeeHeader = props =>{
    const {title} = props;
    return (
        <article className='fee_header'>
            <div>
                <UseTitleHTML html={title} />
            </div>
        </article>
    )
}

export default FeeHeader
