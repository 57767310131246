import React from 'react'; 
import UseLang from '../../hook/UseLang';

const CountDownText = props => {
   const { start, days } = props

   return (<>
      {start && <>
         <UseLang es>Comienza en:</UseLang>
         <UseLang en>Starting in:</UseLang>
         <UseLang pt_br>Começa en:</UseLang>
      </>}
      {days && <>
         <UseLang es>Días</UseLang>
         <UseLang en>Days</UseLang>
         <UseLang pt_br>Dias</UseLang>
      </>}
   </>)
}

export default CountDownText
