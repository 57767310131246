import React, { useContext } from 'react';
import { PostContext } from '../../../../context/DataContext';
import Spin from '../../../animations/loadings/Spin';
  
import './suscribe-form.css'
import UseHTML from '../../../hook/UseHTML';

const SuscribeForm = props => {   
   const {  blogData, charging } = useContext(PostContext);
     
    return ( 
        <>
            {blogData && blogData.content  ? (
            <>
                {!charging ? 
                    <>
                    <div className='suscribe_form'>
                        <img src="/images/lacnic-blog.svg" alt='LACNIC Blog'/>
                        <UseHTML html={blogData.content.rendered} /> 
                    </div>
                    </>
                : <Spin />}
            </>
            ) : undefined}
        </>  
 
    )
}

export default SuscribeForm
