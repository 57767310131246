import React from 'react'
import WebinarModal from './WebinarModal';
import useUrlParams from '../../../components/hook/useUrlParams';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const Webinar = props => {
    const { contents, data } = props;
    const { handleParam } = useUrlParams('webinar', ['propuestas-de-politicas-fpp', 'camino-hacia-redes-ipv6-only', 'configurar-servidores-linux-solamente-con-ipv6', 'que-puedo-encontrar-en-el-evento']);


    return (
        <>
            <article className='preparatory_activity_landing'>
                <div>
                    <UseTitleHTML html={data?.title?.rendered} /> 
                    <div className='select_webinar'>
                        <div>
                            <div>
                                <h2>{contents[0].label}</h2>
                                <p className="preparatory_date">31/08/23 - 19:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam('configurar-servidores-linux-solamente-con-ipv6')}>Info [+]</button>
                            </div>
                        </div>
                        

                        <div>
                            <div>
                                <h2>{contents[2].label}</h2>
                                <p className="preparatory_date">13/09/23 - 19:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam('camino-hacia-redes-ipv6-only')}>Info [+]</button>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2>{contents[3].label}</h2>
                                <p className="preparatory_date">20/09/23 - 18:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam('que-puedo-encontrar-en-el-evento')}>Info [+]</button>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h2>{contents[1].label} </h2>
                                <p className="preparatory_date">26/09/23 - 18:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam('propuestas-de-politicas-fpp')}>Info [+]</button>
                            </div>
                        </div> 
                    </div>
                </div>
            </article>
            <WebinarModal contents={contents} />
        </>

    )
}

export default Webinar
