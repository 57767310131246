import React from 'react';

const MenuButtonItem = props => {
    const { item_name } = props
    return (
        <button className='menu_item'>
            {item_name}
        </button>
    )
}

export default MenuButtonItem
