import React from 'react';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';
import UseLang from '../../../components/hook/UseLang';

const FellowshipProgramPagination = props => {
    const { accordion, page_number } = props
     
    if (page_number === '1') {
        return <Criteria accordion={accordion} next />;
    }
    if (page_number === '2') {
        return (
            <>
                <Objetives accordion={accordion} prev />
                <Successful accordion={accordion} next />
            </>
        )
    }
    if (page_number === '3') {
        return (
            <>
                <Criteria accordion={accordion} prev />
                <Scope accordion={accordion} next />
            </>
        )
    }
    if (page_number === '4') {
        return (
            <>
                <Successful accordion={accordion} prev />
                <Dates accordion={accordion} next />
            </>
        )
    }
    if (page_number === '5') {
        return (
            <>
                <Scope accordion={accordion} prev />
                <Selected accordion={accordion} next />
            </>
        )
    }
    if (page_number === '6') {
        return (
            <>
                <Dates accordion={accordion} prev /> 
            </>
        )
    }

}

export default FellowshipProgramPagination


const Objetives = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('1'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };
  
  const Criteria = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('2'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };
  
  const Successful = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('3'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };
  
  const Scope = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('4'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };
  
  const Dates = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('5'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };
  
  const Selected = (props) => {
    const { accordion, prev, next } = props;
    return (
      <>
        {accordion
          .filter((x) => x.id.includes('6'))
          .map((item, id) => (
            <React.Fragment key={id}>
              <Link to={`./${slugify(item.label)}`}>
                {prev && <Prev />}
                {next && <Next />}
              </Link>
            </React.Fragment>
          ))}
      </>
    );
  };

const Prev = () => {
    return (
        <>
            <UseLang es><i className='icon-arrow-left'></i> Anterior </UseLang>
            <UseLang en><i className='icon-arrow-left'></i> Previous</UseLang>
            <UseLang pt_br><i className='icon-arrow-left'></i> Anterior</UseLang>
        </>
    )
}
const Next = () => {
    return (
        <>
            <UseLang es>Siguiente <i className='icon-arrow-right'></i></UseLang>
            <UseLang en>Next <i className='icon-arrow-right'></i></UseLang>
            <UseLang pt_br>Próximo <i className='icon-arrow-right'></i></UseLang>
        </>
    )
}