import React, { useState, useEffect } from 'react';
import { MenuContext } from './DataContext';
import axios from "axios";

export const MenuState = ({ children }) => {

    let langdefault = 'es'
    const [menuLang, setMenuLang] = useState({ langdefault });

    const menuURL = 'https://apievt.lacnic.net/wp-json/wp/v2/menu';

    const [isMenu, setMenu] = useState([])
    const [loading, setLoadApiMenuData] = useState(false)

     

    useEffect(() => { 
        const loadOurEvents = async () => {
            if (!menuLang) {
                return; // salir si speaker_id es undefined o null
              }
            setLoadApiMenuData(true)
            const responseJSON = await axios.get(`${menuURL}/?lang=${menuLang}`)
            setMenu(responseJSON.data)
            setLoadApiMenuData(false)
        }
        loadOurEvents()
    }, [menuURL, menuLang])

    const handleMenuLang = (value) => {
        setMenuLang(value)
    }
    
    const [eventID, setEventID] = useState(157);
    const eventURL = 'https://apievt.lacnic.net/wp-json/wp/v2/our_events';

    const [id, setID] = useState([])

    useEffect(() => {
        const loadEvent = async () => {
            const responseJSON = await axios.get(`${eventURL}/${eventID}`)
            setID(responseJSON.data.id)
        }
        loadEvent();
    }, [eventURL, eventID])
    const handleEventID = (event_value) => {
        setEventID(event_value)
    }
    

    return (
        <MenuContext.Provider value={{
            handleMenuLang, handleEventID, isMenu, menuLang, id, loading, eventID
        }}>
            {children}
        </MenuContext.Provider>
    );
}

