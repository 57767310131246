import React from 'react'
import UseLang from '../../../../components/hook/UseLang'
import DevelopingTheAgenda from '../agenda-sessions/developing-agenda/DevelopingTheAgenda'
const Social = () => {
    return (
        <> 
            <div className='SocialEvent'>
                <div>
                    <div className='social_image'>
                        <img src="/images/seudomingo-logo.png" alt='Seu Domingo' />
                    </div>
                    <div className='social_data'>
                        <UseLang es>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Seu Domingo Beach Club</h2>
                                <p className="social_date">Evento Social: Mi&eacute;rcoles 04 de Octubre - 20 hs</p>
                            </div>
                            <div>
                                <p>Direcci&oacute;n: Av. Zez&eacute; Diogo, 5531 - Praia do Futuro, Fortaleza - CE, CEP: 60183-696</p>
                            </div>
                            <div>
                                <p>Transporte: Salida desde el Centro Fábrica de Negocios a las 19 hs. </p>
                            </div>

                        </UseLang>
                        <UseLang en>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Seu Domingo Beach Club</h2>
                                <p className="social_date">Social event - Wednesday Oct 4th - 8 PM</p>
                            </div>
                            <div>
                                <p>Address: Av. Zezé Diogo, 5531 - Praia do Futuro, Fortaleza - CE, CEP: 60183-696</p>
                            </div>
                            <div>
                                <p>Transportation: Departure from Fábrica de Negocios Center at 7 PM</p>
                            </div>
                        </UseLang>
                        <UseLang pt_br>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Seu Domingo Beach Club</h2>
                                <p className="social_date">Evento social: quarta-feira 04 - 20 hs</p>
                            </div>
                            <div>
                                <p>Endereço: Av. Zezé Diogo, 5531 - Praia do Futuro, Fortaleza - CE, CEP: 60183-696</p>
                            </div>
                            <div>
                                <p>Transporte: Saida do Centro Fábrica de Negócios às 19h.</p>
                            </div>
                        </UseLang>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Social
