import React from 'react';
import UseLang from '../../hook/UseLang';
import './charging.css'

function Charging() {
    /* const { myLang } = useContext(LangContext) */
    return ( 
        <p className='charging_text'>
            <UseLang es>Cargando...</UseLang>
            <UseLang en>Charging...</UseLang>
            <UseLang pt_br>Carregando...</UseLang> 
        </p> 
    )
}

export default Charging
