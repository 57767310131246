import React, {useState}  from 'react' 
import FeeModal from './FeeModal';
import RegistrationButton from './RegistrationButton';

const RegistrationSlide = props => {
    
    const { registration_url, more_info, modal_window, modal_state} = props;
    const [ showNewsForm, setShowNewsForm ]= useState(false)
    const openNewsForm = () => setShowNewsForm(true)
    const closeNewsForm = () => setShowNewsForm(false) 

    return (
        <div className='registration'> 
            <RegistrationButton openNewsForm={openNewsForm} registration_url={registration_url} more_info={more_info} modal_state={modal_state}/>
            <FeeModal closeNewsForm={closeNewsForm} showNewsForm={showNewsForm} modal_window={modal_window} modal_state={modal_state} /> 
        </div>
    )
}

export default RegistrationSlide
