import React from 'react'; 
import UseLang from '../../hook/UseLang';
import './footer-top.css'
import FooterLogo from './FooterLogo';

const FooterTop = props => { 

    return (
        <nav className='foot_top'>
            <UseLang es> 
                <FooterLogo/>
                <ul>
                    <li><p>Organización</p></li>
                    <li><a href="https://www.lacnic.net/966/1/lacnic/acerca-de-lacnic" target='_blank' rel="noreferrer">Acerca de LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/491/1/lacnic/casa-de-internet" target='_blank' rel="noreferrer">Casa de Internet</a></li>
                    <li><a href="https://www.lacnic.net/18/1/lacnic/reporte-anual" target='_blank' rel="noreferrer">Reporte Anual</a></li>
                    <li><a href="https://www.lacnic.net/490/1/lacnic/empleo" target='_blank' rel="noreferrer">Empleo</a></li>
                </ul>

                <ul>
                    <li><p>Contactar</p></li>
                    <li><a href="https://www.lacnic.net/3907/1/lacnic/sugerencias" target='_blank' rel="noreferrer">Sugerencias</a></li>
                    <li><a href="https://www.lacnic.net/492/1/lacnic/contacto" target='_blank' rel="noreferrer">Contacto</a></li>
                    <li><a href="https://www.lacnic.net/997/1/lacnic/listas-de-discusion" target='_blank' rel="noreferrer">Listas de Discusión</a></li>
                    <li><a href="https://www.lacnic.net/1126/1/lacnic/faq" target='_blank' rel="noreferrer">FAQ</a></li>
                </ul>  
            </UseLang>
            <UseLang en> 
                <FooterLogo/>
                <ul>
                    <li><p>Organization</p></li>
                    <li><a href="https://www.lacnic.net/1004/2/lacnic/about-lacnic" target='_blank' rel="noreferrer">About LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/629/2/lacnic/casa-de-internet" target='_blank' rel="noreferrer">Casa de Internet</a></li>
                    <li><a href="https://www.lacnic.net/78/2/lacnic/annual-report" target='_blank' rel="noreferrer">Annual Report</a></li>
                    <li><a href="https://www.lacnic.net/628/2/lacnic/job-opportunities" target='_blank' rel="noreferrer">Job Opportunities</a></li>
                </ul>
                <ul>
                    <li><p>Contact us</p></li>
                    <li><a href="https://www.lacnic.net/3908/2/lacnic/suggestions" target='_blank' rel="noreferrer">Suggestions</a></li>
                    <li><a href="https://www.lacnic.net/630/2/lacnic/contact-us" target='_blank' rel="noreferrer">Contact us</a></li>
                    <li><a href="https://www.lacnic.net/1035/2/lacnic/discussion-list">Discussion list</a></li>
                    <li><a href="https://www.lacnic.net/1127/2/lacnic/faq" target='_blank' rel="noreferrer">FAQ</a></li>

                </ul> 
            </UseLang>
            <UseLang pt_br> 
                <FooterLogo/>
                <ul>
                    <li><p>Organização</p></li>
                    <li><a href="https://www.lacnic.net/1042/3/lacnic/acerca-do-lacnic" target='_blank' rel="noreferrer">Acerca do LACNIC</a></li>
                    <li><a href="https://www.lacnic.net/767/3/lacnic/casa-da-internet" target='_blank' rel="noreferrer">Casa da Internet</a></li>
                    <li><a href="https://www.lacnic.net/48/3/lacnic/relatorio-anual" target='_blank' rel="noreferrer">Relatório anual</a></li>
                    <li><a href="https://www.lacnic.net/766/3/lacnic/trabalho" target='_blank' rel="noreferrer">Trabalho</a></li>
                </ul>
                <ul>
                    <li><p>Contato</p></li>
                    <li><a href="https://www.lacnic.net/3909/3/lacnic/sugestão" target='_blank' rel="noreferrer">Sugestão</a></li>
                    <li><a href="https://www.lacnic.net/768/3/lacnic/contate-nos" target='_blank' rel="noreferrer">Contate-nos</a></li>
                    <li><a href="https://www.lacnic.net/1073/3/lacnic/listas-de-discussão" target='_blank' rel="noreferrer">Listas de discussão</a></li>
                    <li><a href="https://www.lacnic.net/1128/3/lacnic/faq" target='_blank' rel="noreferrer">FAQ</a></li>
                </ul> 
            </UseLang>
        </nav>
    )
}

export default FooterTop
