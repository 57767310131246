import React, { useContext, useState } from 'react';
import { PostContext } from '../../../../context/DataContext'; 
import UsePost from '../../../../components/hook/UsePost';
import Header from '../../../../components/navegation/header/Header';
import Footer from '../../../../components/navegation/footer/Footer';
import Main from '../../../../components/interface/main/Main'; 
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import './upload-biography.css'
import UseLangLocation from '../../../../components/hook/UseLangLocation';    
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'; 
import UseJotForm from '../../../../components/hook/UseJotForm';  
import UseTitleHTML from '../../../../components/hook/UseTitleHTML';

const UploadBiography = props => { 
    const { handleID, handleApiURL, handleLoading, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id='1074'>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/programme/upload-biography' url_es='/es/programa/subir-biografia' url_pt_br='/pt-br/programa/carregar-biografia' {...props} />
                <Main className='container upload_biography' loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider/>
                            <article>
                                <div> 
                                    <UseTitleHTML html={data?.title?.rendered}/> 
                                    <UseJotForm id='70866365684975' title='LACNIC - Biografías' height='1000px' /> 
                                </div>
                            </article>
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default UploadBiography
