import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from '../../../interface/modal/Modal';
import useUrlParams from '../../../hook/useUrlParams';
import './photos.css'
import PhotoItems from './PhotoItems';
import LogoWhite from '../../../design/logo/LogoWhite';
import UseLang from '../../../hook/UseLang';
import BackButton from '../../goback/BackButton';

const Photos = () => {
    const [multimediaData, setMultimediaData] = useState([]);
    const {  paramValue, display, closeModal } = useUrlParams('multimedia', ['photos'])

    useEffect(() => {
        axios.get('https://apievt.lacnic.net/wp-json/data/multimedia/2623')
            .then(response => {
                setMultimediaData(response.data.acf.photos);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <Modal onCancel={closeModal} show={display} paramValue={'multimedia'} className='photos' class_modal='photo_backdrop'>
                <div>
                    <button onClick={closeModal} className='button_photo_cancel'><i className="icon-xmark" aria-hidden="true"></i></button>
                    <LogoWhite/>
                    <h2><UseLang es>Fotos</UseLang><UseLang en>Photos</UseLang><UseLang pt_br>Fotos</UseLang></h2>
                    {paramValue === 'photos' ? (
                    <>
                        {multimediaData?.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.url ? (
                            <a
                                className='button_photo_link'
                                href={`${item.url}`}
                                target='_blank'
                                rel="noreferrer"
                            >
                                <PhotoItems item={`${item.date}`} />
                            </a>
                            ) : (
                            <button className='button_photo_link' key={index}>
                                <PhotoItems item={`${item.date}`} />
                            </button>
                            )}
                        </React.Fragment>
                        ))}
                        <BackButton className='button_photo_back' onClick={closeModal} />
                    </>
                    ) : undefined}
                </div>
            </Modal>
        </>
    );
}

export default Photos;


