import React from 'react'
import './homepage.css' 
import HomePageContents from './HomePageContents';


const HomePage = props => { 

    return ( 
        <HomePageContents {...props} /> 
    )
}
export default HomePage;

