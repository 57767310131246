import React, { useState, useMemo } from 'react'
import FullBackdropPage from '../../../../../../components/interface/modal/FullBackdropPage'
import UseEmbed from '../../../../../../components/hook/UseEmbed' 

const ModalSlides = props => {
    const { showSlide, closeModal, loading, url } = props
    const [copyClipBoard, setCopyClipBoard] = useState(true);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        setCopyClipBoard(false);
        setTimeout(() => setCopyClipBoard(true), 3000);
    }
    const MemoizedUseEmbed = useMemo(() => <UseEmbed url={url} aria_label='PDF Viewer' />, [url]);
     

      function downloadPDF() {
        const link = document.createElement('a');
        link.href = `${url}`; 
        link.download = `${link.href.split('/').pop()}`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      return (
        <FullBackdropPage loading={loading} show={showSlide} onCancel={closeModal}>
          <div className='slides_fullbackdrop' style={{overflow: 'auto', overflowY: 'scroll', touchAction: 'pan-y'}}>
            {MemoizedUseEmbed}
            <a href={`${url}`} target='_blank' rel="noreferrer" className='button_new_tab'><i className='icon-arrow-up-right-from-square'></i></a>
            <button onClick={downloadPDF} className='button_download_pdf'><i className='icon-download'></i></button>
            <button onClick={copyToClipboard} className='button_link'> {copyClipBoard ? <i className='icon-link'></i> : <i className='icon-check'></i>}</button>
          </div>
        </FullBackdropPage>
      );
      
}

export default ModalSlides
