import React from 'react';
import UseLang from '../../../../../../components/hook/UseLang';
import MyComponent from '../../../../../../components/interface/my-component/MyComponent';

const LiveStreamingButton = props => {
  const { live_streaming, element, streaming_url } = props; 
  return (
    <>
      {Array.isArray(live_streaming) && live_streaming.length > 0 && (
        <p className='live_streaming'>
          <i className='icon-video'></i>
          {live_streaming?.map((item, id) => (
            <React.Fragment key={id}>
              {
                (item === 'live-streaming' && streaming_url) ? (
                  /* Si item es 'live-streaming' y streaming_url existe */
                 <span span className='label'> {streaming_url?.url === '#' ? streaming_url?.title : <MyComponent element={element} to={streaming_url?.url} target={streaming_url?.target}>
                    {streaming_url?.title}
                  </MyComponent>}</span> 
                ) : (item === 'live-streaming') ? (
                  /* Si item es solo 'live-streaming' sin streaming_url */
                  <> 
                  <span span className='label'> 
                    <UseLang es>
                      <MyComponent element={element} to='/streaming'>Transmisión en vivo</MyComponent>
                    </UseLang>
                    <UseLang en>
                      <MyComponent element={element} to='/streaming'>Live Streaming</MyComponent>
                    </UseLang>
                    <UseLang pt_br>
                      <MyComponent element={element} to='/streaming'>Transmissão ao vivo</MyComponent>
                    </UseLang></span>
                    <i className='icon-video'></i> <span span className='label'> 
                   <MyComponent element={element} href='https://rir.la/lacnic40' target='_blank' rel='noreferrer'>Zoom</MyComponent>
                  </span>
                  </>
                ) : null
              }
              {item === 'Zoom' && (
                <span span className='label'> <MyComponent element={element} href='https://rir.la/lacnic40' target='_blank' rel='noreferrer'>Zoom</MyComponent></span>
              )}
            </React.Fragment>
          ))}
        </p>
      )}
    </>
  );
};

export default LiveStreamingButton;
