import React from 'react'
import './cover.css'

const  Cover = props => {
    const { src, children, loading, className } = props
    const cover_image = {
        'background': `url(${src}) 0% 70% / cover no-repeat`,
    }

    const default_cover = {
        'background': '#CCC'
    }

    return (
        <article className={`cover_image ${className}`} style={loading === false ? cover_image : default_cover}>
            {children}
        </article>
    )
}

export default Cover

 