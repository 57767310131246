import React, { useContext } from 'react'
import UseLang from '../../../../components/hook/UseLang';
import { SessionContext } from '../../../../context/DataContext';
import ActivityDetails from './ActivityDetails';
import RoomSlot from './RoomSlot';
import ActivityTime from './ActivityTime';

const ActivitySlot = props => {
    const { item, name, start_time, end_time, day } = props;
    const { selectedDateStr, agendaLACNIC40} = useContext(SessionContext);
 
    const filteredBySessionName = agendaLACNIC40?.filter((item) => {
        return Array.isArray(item.acf.sessions) && item.acf.sessions.some((session) => {
            return session.name === "Tutoriales";
        });
    });
      
      const monday14H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "02/10/2023 14:00";
      });
      const monday09H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "02/10/2023 09:00";
      });
      const monday11H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "02/10/2023 11:30";
      }); 
      const thursday14H = filteredBySessionName.filter((item) => {
        return item.acf.start_time === "02/10/2023 14:00";
      });  
  

    if (item === 'activity') {
        return (
            <div>
                {
                    name === 'DNS' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Espacio DNS en LAC</UseLang>
                            <UseLang en>DNS space at LAC</UseLang>
                            <UseLang pt_br>Espaço DNS na LAC</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Foyer (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {name === 'Registro' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Registration</UseLang>
                            <UseLang pt_br>Registro</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Nuevos participantes' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>New Participants</UseLang>
                            <UseLang pt_br>Novos participantes</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Tutoriales' &&
                    <> 
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        {selectedDateStr ? 
                            <> 
                                { (`${day} ${start_time}` === '02/10/2023 14:00' || `${day} ${start_time}` === '02/10/2023 16:30') &&  <ActivityDetails activity={monday14H} />}
                                {`${day} ${start_time}` === '02/10/2023 09:00' && <ActivityDetails activity={monday09H} /> }
                                {`${day} ${start_time}` === '02/10/2023 11:30' && <ActivityDetails activity={monday11H} /> }
                                {`${day} ${start_time}` === '05/10/2023 14:00' &&  <ActivityDetails activity={thursday14H} />}
                            </>
                        : <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Tutorials</UseLang>
                            <UseLang pt_br>Tutoriais</UseLang>
                            </div>
                        }
                         
                    </>
                }

                {
                    name === 'Reunión Becados' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Fellowship Program</UseLang>
                            <UseLang pt_br>Sessão bolsistas</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Apertura' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Apertura / Plenaria</UseLang>
                            <UseLang en>Opening / Plenary</UseLang>
                            <UseLang pt_br>Abertura / Plenário</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Plenaria' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Plenary</UseLang>
                            <UseLang pt_br>Plenário</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Cierre' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Cierre</UseLang>
                            <UseLang en>LACNIC 40 Wrap-Up</UseLang>
                            <UseLang pt_br>Encerramento</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Foro Técnico' &&
                    <>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Technical Forum</UseLang>
                            <UseLang pt_br>Fórum Técnico</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Foro Público' &&
                    <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Policy Forum</UseLang>
                            <UseLang pt_br>Fórum Público</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {
                    name === 'Asamblea General' &&
                    <>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>General Assembly</UseLang>
                            <UseLang pt_br>Assembleia Geral</UseLang>
                        </div>
                    </>
                }

                {
                    name === 'Internet en México, presente y futuro' &&
                    <>
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Internet in Mexico, present and future</UseLang>
                            <UseLang pt_br>Internet no México, presente e futuro</UseLang>
                        </div>
                    </>
                }

                {
                    name === 'Lunch' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Almuerzo</UseLang>
                            <UseLang en>{name}</UseLang>
                            <UseLang pt_br>Almoço</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Cóctel de Bienvenida' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Welcome Cocktail</UseLang>
                            <UseLang pt_br>Coquetel de Boas vindas</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Evento Social' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>Social Event</UseLang>
                            <UseLang pt_br>Evento Social</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'Break' && 
                    <> 
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                        <div className='ActivityName'>
                            <UseLang es>Receso</UseLang>
                            <UseLang en>{name}</UseLang>
                            <UseLang pt_br>Intervalo</UseLang>
                        </div>
                    </>
                }
                {name === 'BoF' && 
                    <>
                        <div className='ActivityName'>
                            <UseLang es>BoF de mediciones</UseLang>
                            <UseLang en>Measure-<br/>ments BoF</UseLang>
                            <UseLang pt_br>BoF de Mediciones</UseLang>
                        </div>
                    </>
                }
                {
                    name === 'WorkingGroup' &&
                    <>
                        <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                        <div className='ActivityName'>
                            <UseLang es>Grupo de trabajo de Mediciones de Internet</UseLang>
                            <UseLang en>Internet Measurements Working Group</UseLang>
                            <UseLang pt_br>Grupo de Trabalho de Medições da Internet</UseLang>
                        </div>
                        {selectedDateStr && 
                            <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                        }
                    </>
                }
                {name === 'Beer&Gear' &&  <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div>
                </>}
                {name === 'Beer&Peer' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                </> }
                {name === 'First Conf.' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/>
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                    <RoomSlot>Foyer (Piso 2)</RoomSlot>
                    }
                </> }
                {name === 'First Training' &&   <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr &&  <RoomSlot>Foyer (Piso 2)</RoomSlot>}</>} 
                {name === 'First' && <div className='ActivityName'>{name}</div> }
                {name === 'LACNIC Runners' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                </> }
                {name === 'LAC CSIRTs' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/>  
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                            <RoomSlot>Ouro (planta baja)</RoomSlot>
                    }
                </>} 
                {name === 'LAC PF' && <div className='ActivityName'>{name}</div> }
                {name === 'RISE Conf.' && <div className='ActivityName'>{name}</div> }
                {name === 'RISE Training' && <div className='ActivityName'>{name}</div> }
                {name === 'LACNIC' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                    }
                </>}
                {name === 'LACNOG' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Plenaria - Diamante (Piso 2)</RoomSlot>
                    }
                </>} 
                {name === 'Capture the Flag' && <>
                    <ActivityTime start_time={start_time} end_time={end_time} day={day}/> 
                    <div className='ActivityName'>{name}</div> 
                    {selectedDateStr && 
                        <RoomSlot>Ouro (planta baja)</RoomSlot>
                    }
                </>} 
            </div>

        )
    }
}

export default ActivitySlot
 
