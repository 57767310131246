import React, { useState } from 'react';
import useUrlParams from '../../../hook/useUrlParams';
import Modal from '../../../interface/modal/Modal';
import { useNavigate } from 'react-router-dom';
import './stand.css';
import StandDates from './StandDates';
import StandTopics from './StandTopics';
import UseLang from '../../../hook/UseLang';

const Stand = props => {
    const navigate = useNavigate();
    const { paramValue, display, closeModal } = useUrlParams('lacnicStand', ['agenda'])
    const [activeTab, setActiveTab] = useState('2');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`?lacnicStand=agenda&date=${tab}`);
    };

    return (
        <Modal show={display} paramValue={'lacnicStand'} className='lacnicStand' onCancel={closeModal}>
            {paramValue === 'agenda' ?
                <div>
                    <div className='stand_header'>
                        <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
                        <div>
                            <UseLang es><h2>Stand de LACNIC</h2>
                            <p>Durante toda la semana nuestros expertos ofrecerán información acerca de:</p></UseLang>
                            <UseLang en><h2>LACNIC Stand</h2><p>During the week our staff will be available to answer questions about:</p></UseLang>
                            <UseLang pt_br><h2>Estande de LACNIC</h2><p>Durante a semana toda, nossos especialistas vão oferecer informações sobre:</p></UseLang>
                        </div>
                    </div>
                    <div className='stand_agenda'>
                        <div className='stand_dates'>
                            <button className={`tab_button ${activeTab === '2' ? 'active' : ''}`} onClick={() => handleTabClick('2')}>02/10 <StandDates monday /></button>
                            <button className={`tab_button ${activeTab === '3' ? 'active' : ''}`} onClick={() => handleTabClick('3')}>03/10 <StandDates tuesday /></button>
                            <button className={`tab_button ${activeTab === '4' ? 'active' : ''}`} onClick={() => handleTabClick('4')}>04/10 <StandDates wednesday /></button>
                            <button className={`tab_button ${activeTab === '5' ? 'active' : ''}`} onClick={() => handleTabClick('5')}>05/10 <StandDates thursday /></button>
                            <button className={`tab_button ${activeTab === '6' ? 'active' : ''}`} onClick={() => handleTabClick('6')}>06/10 <StandDates friday /></button>
                        </div>
                        <div className='stand_topics'>
                            {activeTab === '2' &&
                                <div> 
                                    <div className='stand_start_time'>11:00 - 13:00 (Fortaleza)</div>
                                    <StandTopics measurement />
                                    <div className='stand_start_time'>14:00 - 15:00 (Fortaleza)</div>
                                    <StandTopics campus  />
                                    <div className='stand_start_time'>15:00 - 16:00 (Fortaleza)</div>
                                    <StandTopics policy />
                                    <div className='stand_start_time'>16:00 - 18:00 (Fortaleza)</div>
                                    <StandTopics frida />
                                </div>
                            }
                            {activeTab === '3' &&
                                <div> 
                                    <div className='stand_start_time'>09:00 - 10:00 (Fortaleza)</div>
                                    <StandTopics policy /> 
                                    <div className='stand_start_time'>10:00 - 11:00 (Fortaleza)</div>
                                    <StandTopics csirt />
                                    <div className='stand_start_time'>11:00 - 13:00 (Fortaleza)</div>
                                    <StandTopics rpki /> 
                                    <div className='stand_start_time'>14:00 - 15:00 (Fortaleza)</div>
                                    <StandTopics campus />
                                    <div className='stand_start_time'>16:00 - 17:00 (Fortaleza)</div>
                                    <StandTopics csirt /> 
                                </div>
                            }
                            {activeTab === '4' && 
                                <div> 
                                     <div className='stand_start_time'>10:00 - 11:00 (Fortaleza)</div>
                                    <StandTopics feedback />
                                    <div className='stand_start_time'>10:00 - 11:00 (Fortaleza)</div>
                                    <StandTopics ipv6 />
                                    <div className='stand_start_time'>14:00 - 16:00 (Fortaleza)</div>
                                    <StandTopics raices />
                                    <div className='stand_start_time'>16:00 - 17:00 (Fortaleza)</div>
                                    <StandTopics lideres />
                                </div>
                            }
                            {activeTab === '5' && 
                                <div> 
                                    <div className='stand_start_time'>09:00 - 12:00 (Fortaleza)</div>
                                    <StandTopics ipv4 />
                                    <div className='stand_start_time'>12:00 - 13:00 (Fortaleza)</div>
                                    <StandTopics measurement />
                                    <div className='stand_start_time'>14:00 - 15:00 (Fortaleza)</div>
                                    <StandTopics measurement />
                                    <div className='stand_start_time'>15:00 - 16:00 (Fortaleza)</div>
                                    <StandTopics feedback />
                                    <div className='stand_start_time'>16:00 - 18:00 (Fortaleza)</div>
                                    <StandTopics ipv4 />
                                </div>
                            }
                            {activeTab === '6' && 
                                <div> 
                                     <div className='stand_start_time'>09:00 - 12:00 (Fortaleza)</div>
                                    <StandTopics ipv4 />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : undefined}

        </Modal>
    )
}

export default Stand
