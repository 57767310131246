import React from 'react'; 
import UseLang from '../../hook/UseLang';

const EventStatistics = props => { 
    return (<>
        <UseLang es><img src="/images/about-event-es.svg" alt="Estadísticas del evento" /></UseLang>
        <UseLang en><img src="/images/about-event-en.svg" alt="Estadísticas del evento" /></UseLang>
        <UseLang pt_br><img src="/images/about-event-pt-br.svg" alt="Estadísticas del evento" /></UseLang>
    </>)
}

export default EventStatistics
