import React from 'react'
import UseLang from '../../../../../components/hook/UseLang'
import './DevelopingTheAgenda.css'

const DevelopingTheAgenda = ({ agenda_state }) => {
    return (
        <> {agenda_state ? 
            <article className='DevelopingTheAgenda'>
                <div>
                    <div>
                        <UseLang es>
                            <p>Estamos trabajando en la elaboraci&oacute;n de la agenda del evento</p>
                            <p>En breve incluiremos m&aacute;s informaci&oacute;n</p>
                        </UseLang>
                        <UseLang en>
                            <p>We are currently finalizing the event agenda</p>
                            <p>More information will be added shortly</p>
                        </UseLang>
                        <UseLang pt_br>
                            <p>Estamos trabalhando na prepara&ccedil;&atilde;o da agenda do evento</p>
                            <p>Em breve incluiremos mais informa&ccedil;&otilde;es</p>
                        </UseLang>
                    </div>
                </div>
            </article> :
            null}</>
    )
}

export default DevelopingTheAgenda
