import React from 'react'; 
import Cover from '../../../components/design/cover/Cover';
import UseHTML from '../../../components/hook/UseHTML';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const InfoCover = props => { 
    const { loading , title, content } = props 
     
    return (
        <Cover className='content' 
            loading={loading} 
            src='https://apievt.lacnic.net/wp-content/uploads/2023/06/fortaleza-brasil-lacnic40.jpg'> 
            <div>
                <UseTitleHTML html={title} />
                <UseHTML html={content} />
            </div> 
        </Cover>
    )
}

export default InfoCover
