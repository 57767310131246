import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';   
import App from './App'; 
import reportWebVitals from './reportWebVitals'; 
import MasterState from './context/MasterState'
import { BrowserRouter } from "react-router-dom"; 
import ScrollToTop from './components/added/ScrollToTop'; 
import FontIcons from './components/design/fonts/FontIcons';

const root = ReactDOM.createRoot(document.getElementById('application'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop> 
        <MasterState>  
            <FontIcons> 
              <App />
            </FontIcons> 
        </MasterState>  
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
