import React,{useEffect} from 'react';
import ReactDOM from 'react-dom'; 
import './modal.css'

const FullOverlay = props => {
    const {  children, loading , className, onCancel} = props
    const content = (
        <div className={`modal full_backdrop`} > 
            <div className='on_cancel'> 
                <button onClick={onCancel}>
                    <i className="icon-xmark"></i>
                </button>
            </div>
            <div className={`full_backdrop_content ${!loading ? 'modal_loading' : ''} ${className ? className : ''}`}>
                {children}
            </div>
        </div>
    )
    return ReactDOM.createPortal(content, document.getElementById('full-backdrop-hook'))
}
const FullBackdropPage = props => {
    const {  show  } = props;
    useEffect(() => {
        document.body.classList.toggle('full-backdrop-open', show);
    },[show]) 
     
    return (
        <>
        {show && <> 
            <FullOverlay {...props} />
        </>
        }

        </>
    )
}
export default FullBackdropPage;