import React from 'react'

const AboutArticle = props => {
    const { className, children } = props;
    return (
        <article className={`about_articles ${className}`}>
            {children}
        </article>
    )
}

export default AboutArticle
