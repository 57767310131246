import React, { useState, useContext } from 'react'
import { PostContext, SessionContext } from '../../../../context/DataContext'  
import UsePost from '../../../../components/hook/UsePost'
import Main from '../../../../components/interface/main/Main'
import Footer from '../../../../components/navegation/footer/Footer'
import Header from '../../../../components/navegation/header/Header'
import TimeCheckbox from '../table-functionalities/TimeCheckbox'
import Timetable from '../timetable/Timetable'
import AgendaHeader from './AgendaHeader'
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement'
import UseTimeOut from '../../../../components/hook/UseTimeOut'
import './agenda-body.css'
import UseLangLocation from '../../../../components/hook/UseLangLocation'
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader'
import DevelopingTheAgenda from '../agenda-sessions/developing-agenda/DevelopingTheAgenda'
 import DayAgendaPicker from '../table-functionalities/DayAgendaPicker'

const AgendaBody = props => { 
    const { handleApiURL, handleID, handleLoading, data} = useContext(PostContext);
    const [loading, setLoading] = useState(true); 
    const { agendaLACNIC40 } = useContext(SessionContext);
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id='670'>
                <Header title={data?.title?.rendered} loading={loading} {...props} />
                <Main className='container schedule_table' status={data?.status} loading={loading}  >
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <DevelopingTheAgenda agenda_state={false} />
                            <SponsorsSlider />
                            <StickyHeader/> 
                            <AgendaHeader title={data?.title?.rendered} />
                            <TimeCheckbox />
                            <DayAgendaPicker agendaData={agendaLACNIC40}/>
                            <Timetable {...props} />
                        </UseTimeOut>
                    </section>
                </Main>
            </UsePost>
            <OrganizersElement />
            <Footer className="master_foot foot_home" />
        </UseLangLocation>
    )
}

export default AgendaBody
