import React from 'react';
import Logo from './Logo';
import HeadStuff from './HeadStuff';
import './Header.css'
import MainNavMenu from './mainNavMenu/MainNavMenu';
import MediaScreenMenu from './mediaScreenMenu/MediaScreenMenu'
import Announcements from '../../announcements/Announcements';


const Header = props => {
    const { className, loading, title } = props

    return (
        <header className={`header header_app ${className} ${title ? '' : 'header_loading'} ${!loading ? '' : 'header_loading'}`}>
            <div className='header_content'>
                <MediaScreenMenu />
                <Logo />
                <MainNavMenu {...props} />
                <HeadStuff {...props} />
                <Announcements/>
            </div>
        </header>
    )
}

export default Header
