import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const SponsorshipContact = props => {
    const { contents } = props;
    return (
        <article className='sponsorship_contact'>
            <div>
                <h2>{contents[2].label}</h2>
                <UseHTML html={contents[2].content} />
            </div>
        </article>
    )
}

export default SponsorshipContact
