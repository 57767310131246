import React from 'react'

const UseEmbed = props => {
    const {className, url,  aria_label } = props;
 
    const randomParam = `?t=${new Date().getTime()}`;
    const pdfUrl = url + randomParam;

   
    return (
        <object  
            className={className}
            data={pdfUrl} 
            type="application/pdf" 
            style={{ width: '100%', height: '100vh', position: 'relative', zIndex: '2', touchAction: 'pan-y' }}
            aria-label={aria_label}></object>
        
    )
}

export default UseEmbed 