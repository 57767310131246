import React  from 'react'
import UseLang from '../../../components/hook/UseLang'; 
import AboutArticle from './AboutArticle';

function CodeOfConduct() { 
    return (
        <AboutArticle className='code_of_conduct'>  
            <div>
                <UseLang es>
                    <h2>Código de conducta</h2>
                    <p>Los eventos de LACNIC est&aacute;n sujetos a los est&aacute;ndares de comportamiento establecidos en el <a href="https://www.lacnic.net/4038/1/lacnic/codigo-de-conducta-de-la-comunidad-de-lacnic">C&oacute;digo de Conducta de la Comunidad de LACNIC</a>. De esta forma buscamos asegurarnos que la
                        actividad se desarrolle dentro de un ambiente inclusivo, seguro y libre de discriminaci&oacute;n y/o violencia de cualquier tipo
                    </p>
                </UseLang>
                <UseLang en> 
                    <h2>Code of Conduct</h2>
                    <p>LACNIC events are subject to the standards of behavior defined in the <a href="https://www.lacnic.net/4039/2/lacnic/lacnic-community-code-of-conduct">
                        LACNIC Community Code of Conduct</a>. The goal is to guarantee that the activities can be
                        carried out in an environment that is inclusive, safe, and free of
                        discrimination and/or violence of any kind.</p>
                </UseLang>
                <UseLang pt_br> 
                    <p><strong>C&oacute;digo de conduta</strong></p>
                    <p>Os eventos do LACNIC est&atilde;o sujeitos aos padr&otilde;es
                        comportamentais estabelecidos no <a href="https://www.lacnic.net/4040/3/lacnic/codigo-de-conduta-da-comunidade-do-lacnic"> C&oacute;digo de Conduta da Comunidade do LACNIC</a>. Assim buscamos garantir
                        que a atividade aconte&ccedil;a em um ambiente inclusivo, seguro e livre de
                        discrimina&ccedil;&atilde;o e/ou viol&ecirc;ncia de qualquer tipo.</p>
                </UseLang>
            </div>
        </AboutArticle>
    )
}

export default CodeOfConduct
