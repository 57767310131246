import React from 'react'

const AccordionWrapper = props => {
    const { children, className} = props;
    return (
        <div className={`accordion ${className}`}>
            {children}
        </div>
    )
}

export default AccordionWrapper
