import React from 'react'
import UseLang from '../../hook/UseLang'
import './sticky-header.css'
import useUrlParams from '../../hook/useUrlParams';

const StickyHeader = props => {
    const { handleParam } = useUrlParams('announcements', ['important-information-about-lacnic-39-registration']);

    const stickyHeader = true
    return (
        <>
           { !stickyHeader ? <article className="sticky_header">
                <div>
                    <p>
                        <button onClick={() => handleParam('important-information-about-lacnic-39-registration')}>
                            <UseLang es>Información importante sobre registro LACNIC 39</UseLang>
                            <UseLang en>Important information about LACNIC 39 registration</UseLang>
                            <UseLang pt_br>Informações importantes para se inscrever no LACNIC39</UseLang>
                        </button>
                    </p>
                </div>
            </article> : undefined}
        </>
    )
}

export default StickyHeader
