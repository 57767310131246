import React from 'react'
import UseLang from '../../hook/UseLang'
import './organizers.css'

const Organizers = props => {
    return (
        <div className='organizers_logos'>
            <h4>
                <UseLang es>Organizadores</UseLang>
                <UseLang en>Organizers</UseLang> 
                <UseLang pt_br>Organizadores</UseLang>
            </h4>
            <div>
                <a href='https://www.lacnic.net/' target="_blank" rel="noreferrer"><img src="/images/lacnic-logo.svg" alt="LACNIC"></img></a>
                <a href="https://lacnog.org/" target="_blank" rel="noreferrer"><img src="/images/lacnog-logo.svg" alt="LACNOG"></img></a>
                <a href="https://nic.br/" target="_blank" rel="noreferrer"><img src="/images/nic-br-logo.svg" alt="NIC.br"></img></a>
            </div>
        </div>
    )
}

export default Organizers
