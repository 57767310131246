import React from 'react'
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const WelcomeKitHeader = props => {
    const { title } = props;
    return ( 
        <UseTitleHTML html={title} /> 
    )
}

export default WelcomeKitHeader
