import React from 'react'
import Organizers from './Organizers';

const OrganizersElement = () => { 
    return (
        <section>
            <article className='organizers'>
                <Organizers />
            </article>
        </section>
    )
}

export default OrganizersElement
