import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const InfoSlide = props => {
    const {more_info, onClick}= props;
    return (
        <UseHTML className='more_info' onClick={onClick} html={more_info} />
    )
}

export default InfoSlide
