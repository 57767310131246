import React  from 'react'
import './toogle-button.css'

const ToogleButton = props =>{
    const {onclick,myTimeZone} = props; 

    return (
        <div className={`toogle_button ${myTimeZone === 'localtime' ? '' : 'active'}`} onClick={onclick}> 
            <span >

            </span>
        </div>
    )
}

export default ToogleButton
