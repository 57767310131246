import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UseLang from '../../../components/hook/UseLang';
import Modal from '../../../components/interface/modal/Modal';


const FeeModal = props => {
    const { modal_window, showNewsForm, closeNewsForm } = props; 

    return (
        <Modal className='rate_modal_window' show={showNewsForm} onCancel={closeNewsForm} loading={true}>
            <UseHTML html={modal_window} />
            <button className='button blue' onClick={closeNewsForm} >
                <UseLang es>Cerrar</UseLang>
                <UseLang en>Close</UseLang>
                <UseLang pt_br>Fechar</UseLang>
            </button>
        </Modal>
    )
}

export default FeeModal
