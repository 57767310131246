import { useContext } from 'react';
import { LangContext } from '../../context/DataContext';

const UseLang = ({ children, es, en, pt_br }) => {
  const { myLang } = useContext(LangContext);
  
  if ((es && myLang === 'es') || (en && myLang === 'en') || (pt_br && myLang === 'pt-br')) {
    return <>{children}</>;
  } else {
    return null; 
  }
};

export default UseLang;