import React from 'react'
import UseLang from '../../../hook/UseLang'

const PhotoItems = props => {
    const  {item} = props
    
    return (
        <>
            {item === 'Lunes' && <><UseLang es>Lunes</UseLang><UseLang en>Monday</UseLang><UseLang pt_br>Segunda-feira</UseLang></> }
            {item === 'Martes' && <><UseLang es>Martes</UseLang><UseLang en>Tuesday</UseLang><UseLang pt_br>Terça-feira</UseLang></> }
            {item === 'Miércoles' && <><UseLang es>Miércoles</UseLang><UseLang en>Wednesday</UseLang><UseLang pt_br>Quarta-feira</UseLang></> }
            {item === 'Jueves' && <><UseLang es>Jueves</UseLang><UseLang en>Thursday</UseLang><UseLang pt_br>Quinta-feira</UseLang></> }
            {item === 'Viernes' && <><UseLang es>Viernes</UseLang><UseLang en>Friday</UseLang><UseLang pt_br>Sexta-feira</UseLang></> }
            {item === 'Cocktail' && <><UseLang es>Cóctel de Bienvenida</UseLang><UseLang en>Welcome Cocktail</UseLang><UseLang pt_br>Coquetel de Boas vindas</UseLang></> }
            {item === 'LACNIC Runners' && <>LACNIC Runners</> }
            {item === 'Social' && <><UseLang es>Evento Social</UseLang><UseLang en>Social Event</UseLang><UseLang pt_br>Evento Social</UseLang></> }
        </>
    )
}

export default PhotoItems
