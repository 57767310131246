import React, { useState, useEffect } from 'react';
import { SponsorContext } from './DataContext';
import axios from "axios";


export const SponsorState = ({ children }) => {
  const [isSponsors, setAllSponsors] = useState([]);
  const [isSponsorsLoading, setSponsorsLoading] = useState(false);

  useEffect(() => {
    async function loadSponsors() {
      try {
        const sponsors = [];
        const response = await fetch('https://apievt.lacnic.net/wp-json/wp/v2/sponsors');
        if (!response.ok) {
          throw new Error(`Failed to fetch sponsors: ${response.status} ${response.statusText}`);
        }
        const totalPages = response.headers.get('X-WP-TotalPages');
        for (let i = 1; i <= totalPages; i++) {
          const pageResponse = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/sponsors?page=${i}`);
          if (!pageResponse.ok) {
            throw new Error(`Failed to fetch sponsors for page ${i}: ${pageResponse.status} ${pageResponse.statusText}`);
          }
          const pageSponsors = await pageResponse.json();
          sponsors.push(...pageSponsors);
        }
        setAllSponsors(sponsors);
        setSponsorsLoading(true);
      } catch (error) {
        console.error(error);
      }
    }
    loadSponsors();
  }, []);
   
  const [loading, setLoading] = useState(false);
  const [sponsors_lacnic40, setSponsors40] = useState(false);
  const [sponsors_lacnic39, setSponsors39] = useState(false);

  const apiUrl = 'https://apievt.lacnic.net/wp-json/wp/v2/sponsors';
  const pageParam = '?per_page=100' 
  const lacnic39 = '&our_events=50'
  const lacnic40 = '&our_events=157'


  useEffect(() => {
    const fetchSponsors = async () => {
      setLoading(true);
      const responseJSON = await axios.get(`${apiUrl}${pageParam}${lacnic40}`);
      setSponsors40(responseJSON.data);
      setLoading(false);
    }
    fetchSponsors()
  }, [apiUrl, pageParam, lacnic40])

  useEffect(() => {
    const fetchSponsors = async () => {
      setLoading(true);
      const responseJSON = await axios.get(`${apiUrl}${pageParam}${lacnic39}`);
      setSponsors39(responseJSON.data);

      setLoading(false);
    }
    fetchSponsors()
  }, [apiUrl, pageParam, lacnic39])

  const [sponsors_categories, setSponsorsCategories] = useState([])
  useEffect(() => {
    const fetchCategoriesSponsors = async () => {
      setLoading(true);
      const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/category_sponsors?per_page=100`);
      setSponsorsCategories(responseJSON.data);
      setLoading(false);
    }
    fetchCategoriesSponsors()
  }, [])

  return (
    <SponsorContext.Provider value={{
      isSponsors,
      isSponsorsLoading,
      loading, 
      sponsors_lacnic39,
      sponsors_lacnic40,
      sponsors_categories
    }}>
      {children}
    </SponsorContext.Provider>
  );
} 