import React, { useState, useContext } from 'react';
import { PostContext } from '../../../context/DataContext';
import Modal from '../../../components/interface/modal/Modal';
import './welcome.css'
import SuscribeForm from '../../../components/interface/forms/suscribe/SuscribeForm';
import WelcomeContent from './WelcomeContent';

const Welcome = props => {
    const { loading } = useContext(PostContext)
    const [showNewsForm, setShowNewsForm] = useState(false)

    const openNewsForm = () => setShowNewsForm(true)
    const closeNewsForm = () => setShowNewsForm(false)

    return (
        <>
            <Modal show={showNewsForm} onCancel={closeNewsForm} loading={loading} >
                <SuscribeForm />
            </ Modal>
            <WelcomeContent onClick={openNewsForm} {...props} />
        </>
    )
}

export default Welcome
