import React from 'react'
import FootSocial from './FootSocial'
import FooterLegal from './FooterLegal'
import './footer-bottom.css'

function FooterBottom() {
    return (
        <nav className='foot_bottom'>
            <FooterLegal/>
            <FootSocial/>
        </nav>
    )
}

export default FooterBottom
