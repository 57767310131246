import React from 'react'
import UseHTML from '../../../../../../components/hook/UseHTML';
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationDescription = props => {
    const {description} = props;
    return (
        <>
        {description.info_es.description ?  <UseLang es><UseHTML className='presentation_description' html={description.info_es.description}/></UseLang>: null}
        {description.info_en.description ?  <UseLang en><UseHTML className='presentation_description' html={description.info_en.description}/></UseLang>: null}
        {description.info_pt_br.description ?  <UseLang pt_br><UseHTML className='presentation_description' html={description.info_pt_br.description}/></UseLang>: null}
        </> 
    )
}

export default PresentationDescription
