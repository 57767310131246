import React, {useState} from 'react'
import AccordionWrapper from '../../../components/interface/accordion/AccordionWrapper';
import AccordionItem from '../../../components/interface/accordion/AccordionItem';

const InfoAccordion = ({ accordion }) => {
    const [active, setActive] = useState(null);
  
    const handleToggle = (index) => {
      if (active === index) {
        setActive(null);
      } else {
        setActive(index);
      }
    };
  
    return (
      <article className='accordion_info_gral'>
        <AccordionWrapper>
          {Array.isArray(accordion) &&
            accordion.map((item, index) => (
              <AccordionItem
                key={index}
                active={active}
                handleToggle={handleToggle}
                label={item.label}
                id={item.id}
                content={item.content}
              />
            ))}
        </AccordionWrapper>
      </article>
    );
  };
  
  export default InfoAccordion;