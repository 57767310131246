import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useUrlParams = (paramName, validValues) => {
    const navigate = useNavigate();

   

  const [paramValue, setParamValue] = useState(null);
  const [display, setDisplay] = useState(false); 
  

  useEffect(() => {
    document.body.classList.toggle('display_modal', display); 
}, [display]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(paramName);

    if (value && validValues.includes(value)) {
      setParamValue(value);
      setDisplay(true);
    } else {
      setParamValue(null);
      setDisplay(false);
    }
  }, [display, paramName, validValues]);

  const handleParam = (paramValue) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(paramName, paramValue);
    navigate(`?${searchParams.toString()}`);
  };

  const closeModal = () => {
    setDisplay(false);

    // Eliminar los parámetros de consulta de la URL
    const url = window.location.href.split('?')[0];
    window.history.replaceState(null, null, url);
  };

  return { paramValue, display, handleParam, closeModal };
};

export default useUrlParams;

 