import React from 'react';
import { Link } from 'react-router-dom';

function MyComponent(props) {
  const { element: Element = Link, to = props.href, children, ...rest } = props;
  const ElementToRender = Element;

  return (
    <ElementToRender to={to} {...rest}>
      {children}
    </ElementToRender>
  );
}

export default MyComponent;
