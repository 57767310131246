import React, {useEffect} from 'react';
import ReactDOM from 'react-dom'; 
import './media-screen-menu.css'

const MenuOverlay = props => {
    const {children, onCancel} = props;
    const media_screen_menu = (
        <div className='menu_item_overlay'>
            <div className="main_menu_before" onClick={onCancel}></div> 
            {children}
        </div>
    )
    
    return ReactDOM.createPortal(media_screen_menu, document.getElementById('media-screen-menu'))
}

const MediaScreenMenuBox = props => {
    const { show } = props;
    useEffect(() => {
        document.body.classList.toggle('menu-open', show);
    },[show]) 

    return(
    <> {show  && <MenuOverlay  {...props} />}</>)    
}
export default MediaScreenMenuBox
