
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import FullBackdropPage from '../../../../../../components/interface/modal/FullBackdropPage';
import './biography-modal.css'
import BiosTemplate from '../../../../speakers/bios/BiosTemplate';
import UseTimeOut from '../../../../../../components/hook/UseTimeOut';  
import BackButton from '../../../../../../components/navegation/goback/BackButton';

const LoadBiography = props => {
    const { speaker_id, show, onCancel } = props;
    const apiURL = 'https://apievt.lacnic.net/wp-json/data/biographies'
    const [bio, setBio] = useState(null);
    const [charging, setCharging] = useState(true)

    useEffect(() => {
        const fetchPosts = async () => {
          if (!speaker_id) {
            return; // salir si speaker_id es undefined o null
          }
          setCharging(true);
          const responseJSON = await axios.get(`${apiURL}/${speaker_id}`);
          setBio(responseJSON.data);
          setCharging(false);
        };
        fetchPosts();
      }, [apiURL, speaker_id]);
 

    if (bio) {
        return (
            <FullBackdropPage show={show} onCancel={onCancel} loading={!charging}>
                <section className='modal_biography'>
                    <UseTimeOut timeout='1000' loading={charging} setLoading={setCharging} title={bio.title.rendered}>
                        <article className='biography'>
                            <div>
                                <BiosTemplate bio={bio} />
                                <BackButton onClick={onCancel} />
                            </div>
                        </article>
                    </UseTimeOut>
                </section>
            </FullBackdropPage>
        )
    }
}

export default LoadBiography
