import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang';
import SpeakerName from './SpeakerName';
import SpeakerType from './SpeakerType';

const Speakers = props => {
    const { instructor, speakers, presentation_id, moderator, modal } = props;

    return (
        <>
            {moderator ?
                <>
                    <div>
                        <UseLang es>
                            <SpeakerType speaker_type={moderator} plural='Moderadores' singular='Moderador' />
                        </UseLang>
                            <UseLang en>
                                <SpeakerType speaker_type={moderator} plural='Moderators' singular='Moderator' />
                            </UseLang>
                            <UseLang pt_br>
                                <SpeakerType speaker_type={moderator} plural='Moderadores' singular='Moderador' />
                            </UseLang> 
                        </div> 
                    <SpeakerName title={moderator} presentation_id={presentation_id}  modal={modal} />
                </> :
                undefined
            }

            {instructor ?
                <>
                    <div> 
                    <UseLang es>
                        <SpeakerType speaker_type={instructor} plural='Instructores' singular='Instructor' />
                    </UseLang>
                    <UseLang en>
                        <SpeakerType speaker_type={instructor} plural='Instructors' singular='Instructor' />
                    </UseLang>
                    <UseLang pt_br>
                        <SpeakerType speaker_type={instructor} plural='Instrutores' singular='Instrutor' />
                    </UseLang>
                    <SpeakerName title={instructor} presentation_id={presentation_id}  modal={modal}/>
                    </div>  
                </> :
                undefined
            }
            {speakers ? <>
                     <div> 
                    <UseLang es>
                        <SpeakerType speaker_type={speakers} plural='Oradores' singular='Orador' />
                    </UseLang>
                    <UseLang en>
                        <SpeakerType speaker_type={speakers} plural='Speakers' singular='Speaker' />
                    </UseLang>
                    <UseLang pt_br>
                        <SpeakerType speaker_type={speakers} plural='Palestrantes' singular='Palestrante' />
                    </UseLang>
                </div>  
                <SpeakerName title={speakers} presentation_id={presentation_id} modal={modal} />
            </> :
                undefined
            }



        </>
    )
}

export default Speakers
