import React from 'react'; 
import LacnicLogoWhite from '../../design/logo/LacnicLogoWhite';
import UseLang from '../../hook/UseLang';
import ExternalButton from '../buttons/ExternalButton';

function FooterLogo() { 
    return (
        <ul>
            <li>
                <LacnicLogoWhite />
            </li>
            <li>
                <UseLang es>
                    <ExternalButton className="blue" to="https://news.lacnic.net/" target="_blank">
                        Novedades <i className='icon-arrow-right-long'></i>
                    </ExternalButton>
                </UseLang>
                <UseLang en>
                    <ExternalButton className="blue" to="https://news.lacnic.net/en" target="_blank">
                        News <i className='icon-arrow-right-long'></i>
                    </ExternalButton>
                </UseLang>
                <UseLang pt_br>
                    <ExternalButton className="blue" to="https://news.lacnic.net/pt-br" target="_blank">
                        Novidades <i className='icon-arrow-right-long'></i>
                    </ExternalButton>
                </UseLang>
            </li>
        </ul>
    )
}

export default FooterLogo
