import React, { useState, useEffect } from 'react';
import axios from "axios";
import Ellipsis from '../../../animations/loadings/Ellipsis';
import TransferButtonData from './TransferButtonData';

const FetchDropdownLinks = props => {
  const { url_api, id_menu, item_category, menuLang, eventID } = props;
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${url_api}/?lang=${menuLang}&our_events=${eventID}`);
        setApiData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url_api, eventID, menuLang]);

  if (loading) {
    return <Ellipsis />;
  }

  if (error) {
    return <div>Error al cargar los datos. Inténtalo de nuevo más tarde.</div>;
  }

  return (
    <TransferButtonData
      menuLang={menuLang}
      id_menu={id_menu}
      data={apiData}
      item_category={item_category}
    />
  );
};

export default FetchDropdownLinks;
