import React,{useEffect} from 'react';
import ReactDOM from 'react-dom'; 
import './modal.css'

const ModalOverlay = props => {
    const {  children, onCancel, loading , className, class_modal} = props
    const content = (
        <div className={`modal main_modal ${class_modal ? class_modal : '' }`} >
            <div className="main_modal_before" onClick={onCancel}></div>
            <div className={`main_modal_content ${!loading ? 'modal_loading' : ''} ${className ? className : ''}`}>
                {children}
            </div>
        </div>
    )
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'))
}
const Modal = props => {
    const {  show  } = props;
    useEffect(() => {
        document.body.classList.toggle('modal-open', show);
    },[show]) 
     
    return (
        <>
        {show && <> 
            <ModalOverlay {...props} />
        </>
        }

        </>
    )
}
export default Modal;