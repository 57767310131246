import React from 'react' 

const MediaScreenBars = props => {
    const {onClick} = props;
    return ( 
        <div className='media_screen_bars' onClick={onClick}> 
            <button>
                <span></span>
                <span></span>
                <span></span>
            </button>  
        </div> 
    )
}

export default MediaScreenBars
