import React, { useContext, useState } from 'react'
import { PostContext  } from '../../../../context/DataContext';
import Main from '../../../../components/interface/main/Main'; 
import Header from '../../../../components/navegation/header/Header';
import Footer from '../../../../components/navegation/footer/Footer';
import UsePost from '../../../../components/hook/UsePost';  
import SessionHeader from './session-header/SessionHeader';
import Presentations from './presentations-content/Presentations'; 
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider';
import './event-session.css'   
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader';
import DevelopingTheAgenda from './developing-agenda/DevelopingTheAgenda';

const EventSession = props => {
    const { session_id, current_session } = props;
    const { handleID, handleApiURL, handleLoading, sessionId, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true) 
    const href = `${window.location.href}`  

    const queryParams = new URLSearchParams(window.location.search);
    const day = queryParams.get('day'); 
    
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/sessions' handleLoading={handleLoading} handleID={handleID} id={session_id}>
                <Header
                    url_es={`/es/programa/agenda/${href.includes(`${window.location.host}/en`) === true  ? `${data?.wpml_translations?.[1]?.slug}${day ? `?day=${day}` : ''}` : ''}${href.includes(`${window.location.host}/pt-br`) === true  ? `${data?.wpml_translations?.[1]?.slug}${day ? `?day=${day}` : ''}` : ''}`}
                    url_en={`/en/programme/agenda/${data?.wpml_translations?.[0]?.slug}${day ? `?day=${day}` : ''}`}
                    url_pt_br={`/pt-br/programa/agenda/${href.includes(`${window.location.host}/es`) === true  ? `${data?.wpml_translations?.[1]?.slug}${day ? `?day=${day}` : ''}` : ''}${href.includes(`${window.location.host}/en`) === true  ? `${data?.wpml_translations?.[0]?.slug}${day ? `?day=${day}` : ''}` : ''}`}
                    title={data?.title?.rendered} loading={loading} {...props} />
                <Main status={data?.status} className='container event_session' loading={loading} >
                    <section> 
                        <UseTimeOut timeout='1000' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <DevelopingTheAgenda agenda_state={false} />
                            <SponsorsSlider/>
                            <StickyHeader/> 
                            <SessionHeader title={data?.title?.rendered} content={data?.content?.rendered} id={data?.id}/>   
                            <Presentations session_id={sessionId} current_session={current_session} id={data?.id}/>  
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}
export default EventSession 