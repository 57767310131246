import React from 'react';
import CountDown from '../../../components/interface/count-down/CountDown';
import './main-image.css';
/* import { Link } from 'react-router-dom';
import UseLang from '../../../components/hook/UseLang'; */

const MainImage = props => {
    const { featured_content, featured_image } = props;

    const bannerStyles = {
        'background': `url(${featured_image}) 0% 70% / cover no-repeat`,
    }

    return (
        <div className="main_image" style={bannerStyles}>
            <div className="main_image_backdrop_filter">
                <div>
                    <CountDown />
                    <div className='homepage_header'>
                        <h1>
                            {featured_content}
                        </h1>
                        {/* <p>
                            <UseLang es>
                                <Link className="button yellow" to="https://lacnic40.lacnic.net/es/streaming">Transmisión en vivo</Link>
                            </UseLang>
                            <UseLang en>
                                <Link className="button yellow" to="https://lacnic40.lacnic.net/en/streaming">Live streaming</Link>
                            </UseLang>
                            <UseLang pt_br>
                                <Link className="button yellow" to="https://lacnic40.lacnic.net/pt-br/streaming">Transmissão ao vivo</Link>
                            </UseLang>
                        </p> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImage
