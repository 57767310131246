import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang'

const Room = props => {
    const {room} = props;
    return (
        <>{
            room ? <p className='presentation_room'>

                <i className='icon-door-closed'></i>
                <span className='label'>
                    <UseLang es>Sala:</UseLang> <UseLang en>Room:</UseLang> <UseLang pt_br>Sala:</UseLang>
                    {room}
                </span>
            </p>
                :
                undefined}
        </>
    )
}

export default Room
