import React from 'react'  
import UseLang from '../../hook/UseLang';

const BackButton = props => { 
    const {className, onClick} = props; 
    /* Regresar a la anterior página por medio del historial de navegación usando react-router-dom */
    return (  
        <button className={`back_button button blue ${className ? className : undefined}`} onClick={onClick}>
            <i className='icon-arrow-left-long'></i> 
            <UseLang es> Volver</UseLang>
            <UseLang en> Back</UseLang>
            <UseLang pt_br> Voltar</UseLang>
        </button>
    )
}

export default BackButton;