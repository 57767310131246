import React from 'react';
import './statistics.css';

const StatisticsWrapper = props => {
    const {children} = props;
    return (
        <article className='event_statistics'>
            <div>
                {children}
            </div>
        </article>
    )
}

export default StatisticsWrapper
