import React from 'react';  
import ActivitySlot from '../table-data/ActivitySlot';
import EmptySlot from '../table-data/EmptySlot';
import HourRow from '../table-data/HourRow';
import HeaderDates from '../table-header/HeaderDates';
import HeaderTime from '../table-header/HeaderTime';
import TableElement from './TableElement';

const TableCell = props => {  
    return(
        <TableElement {...props}> 
            <HeaderTime {...props}/>
            <HeaderDates {...props} />
            <HourRow {...props} /> 
            <ActivitySlot {...props}/>
            <EmptySlot {...props} />
        </TableElement> 
        
    )
}

export default TableCell
