import React from "react";
/* import YouTube from 'react-youtube'; */
import lacnic38Video from './lacnic39-summary.mp4'

export default function VideoSummary() {
  return (
    <video width="1000" height="240" className="about_lacnic38_video" autoPlay loop muted>
      <source src={lacnic38Video} type="video/mp4" ></source>  
    </video>
  );
}
