import React, { useContext } from 'react';
import { TimeZoneContext } from '../../../../context/DataContext';
const { format: formatTz } = require("date-fns-tz");



const HourRow = props => {
    const { time, item } = props

    if (item === 'time') {
        return (
            <UtcTime time={time} />
        )
    }
}

export default HourRow

const UtcTime = props => {
    
    const { time } = props; 
    const { myTimeZone, gmt } = useContext(TimeZoneContext)


    const localtime = `${myTimeZone === 'America/Fortaleza' ? `${time}` : `${time} GMT-0300`
        }`

    const dateLocalTime = new Date(localtime);
    const utcTime =
        `${myTimeZone === 'America/Fortaleza' ? `${time} ${gmt}` : `${time}`
        }`

    const dateUtcTime = new Date(utcTime);

    const showTime = ` 
        ${myTimeZone === 'localtime' ? `${formatTz(dateUtcTime, 'HH:mm')}` : `${formatTz(dateLocalTime, 'HH:mm')}`}
        
    `;

    return (
        <>
            {showTime}
        </>
    )
}




