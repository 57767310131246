import React, { useContext } from 'react';
import RoomSlot from './RoomSlot';
import UseLang from '../../../../components/hook/UseLang';
import { SessionContext } from '../../../../context/DataContext';

const ActivityDetails = ({ activity }) => {
    const { tableStyles } = useContext(SessionContext);

    const t3Grid = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center'
    };

    const t2Grid = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center'
    };

    const t1Grid = {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        gridGap: '14px'
    };

    const styles = activity.length === 3 ? (tableStyles === 'StandardTable' ? t3Grid : t1Grid) : (tableStyles === 'StandardTable' ? t2Grid : t1Grid);

    return (
        <div style={styles}>
            {activity.map((item, id) => (
                <div key={id}><div className='ActivityName'>
                    <UseLang es>{item?.acf?.info_es?.title}</UseLang> 
                    <UseLang en>{item?.acf?.info_en?.title}</UseLang> 
                    <UseLang pt_br>{item?.acf?.info_pt_br?.title}</UseLang> 
                    </div>
                    
                    <RoomSlot>{item?.acf?.room}</RoomSlot>
                </div>

            ))}
        </div>
    );
};

export default ActivityDetails;
