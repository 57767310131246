import React from 'react'

const LogoColor = props => {
    const {className, width} = props;
    return (
        <img src="/images/lacnic39-logo-color.svg" alt="LACNIC 39" title="LACNIC 39" width={`${width}`} className={`logo_lacnic39 ${className}`} />
    )
}

export default LogoColor
