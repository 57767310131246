import React from 'react'

const SelectSpeaker = props => {
    const { onClick, title, modal } = props
     
    return (
        <div>
            <i className='icon-user-1'></i>
            {modal === true ? <button onClick={onClick}>
                {title}</button>
            : <p>{title}</p> 
            } 
        </div>
        
    )
}

export default SelectSpeaker
