import React, { useContext,  useState } from 'react';  
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseLangLocation from '../../../../components/hook/UseLangLocation';
import UsePost from '../../../../components/hook/UsePost';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import Main from '../../../../components/interface/main/Main';
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader';
import Footer from '../../../../components/navegation/footer/Footer';
import Header from '../../../../components/navegation/header/Header';
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider';
import { PostContext, BiographyContext } from '../../../../context/DataContext';
import SpeakersList from './SpeakersList';

const Speakers = props => { 
    const {  handleApiURL, handleID,  handleLoading, data } = useContext(PostContext);
    const { bios } = useContext(BiographyContext);
    const [loading, setLoading] = useState(true);
    
    
    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id='1343' >
                <Header title={data?.title?.rendered} url_es='/es/programa/oradores' url_pt_br='/pt-br/programa/palestrantes' url_en='/en/programme/speakers' loading={loading} {...props} />
                <Main className='container' status={data?.status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <SponsorsSlider/> 
                            <StickyHeader/> 
                            <SpeakersList title={data?.title?.rendered} bios={bios}/>
                            
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default Speakers
