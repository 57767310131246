import React from 'react' 
import UseHTML from '../../../../../components/hook/UseHTML';
import GoBackAgenda from './GoBackAgenda';
import UseTitleHTML from '../../../../../components/hook/UseTitleHTML';

const SessionHeader = props => {
    const {title, content} = props;
     
 
    return (
        <article className='session_main_header'>
            <div>
                <h5>Agenda</h5>
                <UseTitleHTML html={title} className='session_name_header' />
                <UseHTML className="session_description" html={content} />
                <GoBackAgenda /> 
            </div>
        </article>
    )
}

export default SessionHeader
