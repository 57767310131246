import { useEffect, useContext } from 'react';
import { LangContext } from '../../context/DataContext';

const languageMap = {
  '/es': 'es',
  '/en': 'en',
  '/pt-br': 'pt-br',
};

const UseLangLocation = ({ children }) => {
  const { setMyLang } = useContext(LangContext);

  useEffect(() => {
    const href = window.location.href;

    Object.entries(languageMap).forEach(([route, lang]) => {
      if (href.includes(`${window.location.host}${route}`)) {
        setMyLang(lang);
      }
    });
  }, [setMyLang]);

  return <>{children}</>;
};

export default UseLangLocation;