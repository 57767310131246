import React from 'react'  
import { Link, NavLink } from 'react-router-dom';
import UseHTML from '../../../hook/UseHTML';

const DropdownLinks = props => {
    
    const { item_category, menuLang, slug, rendered, enable_menu, id_menu, redirect_link, external_link } = props; 
  
    if (enable_menu.includes(id_menu)) {
        return (
            <>
                {!external_link ?
                    <li >
                        {slug==='lacnic-stand' ? <Link to="?lacnicStand=agenda" className='mainNavMenu_dropdownBox_link'>
                            <UseHTML html={rendered} /> 
                        </Link> :
                        <>
                        {
                            <NavLink
                                to={`/${menuLang}/${item_category}/${slug}`}
                                className='mainNavMenu_dropdownBox_link' >
                                <UseHTML html={rendered} />
                            </NavLink>
                        }
                        </> }
                    </li>
                    :
                    <li>
                        <a href={`${redirect_link}`} className='mainNavMenu_dropdownBox_link' target="_blank" rel="noreferrer"><UseHTML html={rendered} /></a>
                    </li>
                }</>
        )
    }
}

export default DropdownLinks;