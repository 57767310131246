import React from 'react'

const LacnicLogoWhite = props => {
    const {className, width} = props;
    return (
        <img src="/images/lacnic-white-logo.svg" alt="LACNIC" title="LACNIC" width={`${width}`} className={`lacnic_white_logo ${className}`} />
    )
}

export default LacnicLogoWhite
