import React from 'react'
import UseLang from '../../../../components/hook/UseLang'
import DevelopingTheAgenda from '../agenda-sessions/developing-agenda/DevelopingTheAgenda'

const Cocktail = () => {
    return (
        <>
            <div className='WelcomeCocktail'>
                <div>
                    <div className='social_image'>
                        <img src="/images/colosso-logo.png" alt='Seu Domingo' />
                    </div>
                    <div className='social_data'>
                        <UseLang es>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Colosso</h2>
                                <p className="social_date">Cóctel de bienvenida - Lunes 02 de Octubre - 19hs</p>
                            </div>
                            <div>
                                <p>Direcci&oacute;n:  Av. Hermenegildo Sá Cavalcante, s/n - Edson Queiroz, Fortaleza - CE, CEP: 60811-665</p>
                            </div>
                            <div>
                                <p>Transporte: salida desde el hotel Praia Centro a las 18:15 hs. </p>
                            </div>

                        </UseLang>
                        <UseLang en>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Colosso</h2>
                                <p className="social_date">Welcome cocktail: Monday Oct 2nd  - 7 PM</p>
                            </div>
                            <div>
                                <p>Address: Av. Hermenegildo Sá Cavalcante, s/n - Edson Queiroz, Fortaleza - CE, CEP: 60811-665</p>
                            </div>
                            <div>
                                <p>Transportation: departure from Praia Centro hotel at 6:15 PM  </p>
                            </div>
                        </UseLang>
                        <UseLang pt_br>
                            <DevelopingTheAgenda agenda_state={false} />
                            <div> 
                                <h2>Colosso</h2>
                                <p className="social_date">Coquetel de boas-vindas: segunda-feira 02 - 19h</p>
                            </div>
                            <div>
                                <p>Endereço: Av. Hermenegildo Sá Cavalcante, s/n - Edson Queiroz, Fortaleza - CE, CEP: 60811-665</p>
                            </div>
                            <div>
                                <p>Transporte: saída do hotel Praia Centro às 18:15 h. </p>
                            </div>
                        </UseLang>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cocktail
