import React from 'react'
import UseLang from '../../../../components/hook/UseLang'

const RoomSlot = ({ children }) => {
    return (
    <div className='ActivityRoom'>
        <UseLang es>Sala: {children}</UseLang>
        <UseLang en>Room: {children}</UseLang>
        <UseLang pt_br>Sala: {children}</UseLang>
    </div>)
}


export default RoomSlot
