import React from 'react'
import UseLang from '../../../hook/UseLang';

const StandDates = props => {
    const { monday, tuesday, wednesday, thursday, friday} = props;
    return (
        <span>
            <UseLang es>
                {monday ? 'Lunes' : undefined}
                {tuesday ? 'Martes' : undefined}
                {wednesday ? 'Miércoles' : undefined}
                {thursday ? 'Jueves' : undefined}
                {friday ? 'Viernes' : undefined}
            </UseLang>
            <UseLang en>
                {monday ? 'Monday' : undefined}
                {tuesday ? 'Tuesday' : undefined}
                {wednesday ? 'Wednesday' : undefined}
                {thursday ? 'Thursday' : undefined}
                {friday ? 'Friday' : undefined}
            </UseLang>
            <UseLang pt_br>
                {monday ? 'Segunda' : undefined}
                {tuesday ? 'Terça' : undefined}
                {wednesday ? 'Quarta' : undefined}
                {thursday ? 'Quinta' : undefined}
                {friday ? 'Sexta' : undefined}
            </UseLang>
        </span>
    )
}

export default StandDates
