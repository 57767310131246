import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import LogoColor from '../../components/design/logo/LogoColor';
import UseTimeOut from '../../components/hook/UseTimeOut';
import Main from '../../components/interface/main/Main';
import Footer from '../../components/navegation/footer/Footer';
import Header from '../../components/navegation/header/Header'; 
import './404.css' 

export default function NoMatch(){
    const uselocation = useLocation();
    const url = uselocation.pathname;
    const [loading, setLoading] = useState(true);
    const title = 'No match' 
    const status = 'publish'
    return( 
        <>
        <Header />
        <Main className='container' status={status} loading={loading}>
            <UseTimeOut  timeout='700' loading={loading} setLoading={setLoading} title={title} >  
                <div className="page_not_found"> 
                    <div> 
                        <Link to="/" title="LACNIC" >
                            <LogoColor  width="200px" />
                        </Link> 
                        <p><strong>404 error</strong></p>
                        <p>
                            No hemos encontrado 
                            <code>{url}</code> 
                            en nuestro servidor.
                        </p>  
                    </div>
                </div>    
            </UseTimeOut>
        </Main>
        <Footer className="master_foot foot_home"  />
        </>
         
    )
}