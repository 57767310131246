import React from 'react'
import Modal from '../../../../../../components/interface/modal/Modal';
import BackButton from '../../../../../../components/navegation/goback/BackButton';
import DynamicMeetingBoard from '../presentation-modal/DynamicMeetingBoard';
import PresentationDescription from '../presentation-modal/PresentationDescription';
import Slides from '../presentation-modal/Slides';
import Speakers from '../presentation-modal/Speakers';
import Videos from '../presentation-modal/Videos';
import PresentationTime from './PresentationTime';
import PresentationTitle from './PresentationTitle';

const PresentationModal = props => {
    const {onCancel, show, item, presentation_id} = props; 
      
    return (
        <Modal show={show} onCancel={onCancel} className="presentation_modal">
            <div className='close_modal' >
                <button onClick={onCancel}>
                    <i className="icon-xmark" aria-hidden="true"></i>
                </button>
            </div>
            <div className='content_modal'> 
                <PresentationTitle title={item} element='h3'/>
                <PresentationTime start_time={item?.start_time} ending_time={item?.ending_time} />
                <DynamicMeetingBoard start_time={item?.start_time} dynamic={item?.dynamic} format={item?.format} room={item?.room} interpretation={item?.interpretation} languaje={item?.languaje} live_streaming={item?.live_streaming} streaming_url={item?.streaming_url}   />
                <Slides presentation={item?.presentation} title_status={true}/> 
                <Videos videos_es={item?.videos} videos_en={item?.videos_en} videos_pt_br={item?.videos_pt_br}  />
                <PresentationDescription description={item} />  
                <Speakers presentation_id={presentation_id} instructor={item?.instructor} speakers={item?.speakers} moderator={item?.moderator} modal={true}  />
                <BackButton onClick={onCancel} />
            </div>
        </Modal>
    )
}

export default PresentationModal
