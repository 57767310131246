import React, { useContext } from 'react';
import { SponsorContext } from '../../../context/DataContext';
import UseLang from '../../hook/UseLang';
import UseHTML from '../../hook/UseHTML';

const SponsorCategories = (props) => {
    const { id_category } = props;
    const { sponsors_categories } = useContext(SponsorContext);

    // Filtra las categorías que coinciden con los IDs en id_category
    const matchingCategories = sponsors_categories.filter(item => id_category.includes(item.id));

    return (
        <>
            {matchingCategories.map(item => {
                const { id, name, slug, acf } = item;
                return (
                    <p key={id} className={`sponsor_tag ${slug}`}>
                        <>
                            <UseLang es><UseHTML html={name} /> </UseLang>
                            <UseLang en><UseHTML html={acf?.name_en} /></UseLang>
                            <UseLang pt_br><UseHTML html={acf?.name_pt_br} /></UseLang>
                        </>
                    </p>
                );
            })}
        </>
    );
}

export default SponsorCategories;
