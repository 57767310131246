import React from 'react';
import FeaturedLinks from './FeaturedLinks';
import MainImage from './MainImage';
import './frontpage.css' 

const FrontPage = props => {
    const {featured_links} = props;
    return (
        <article className="front_page">
            <MainImage {...props} /> 
            <FeaturedLinks featured_links={featured_links}  {...props} /> 
        </article>
    )
}

export default FrontPage 