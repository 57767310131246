import React, { useContext } from 'react'
import { SessionContext } from '../../../../context/DataContext';
import HourRow from './HourRow';
import HeaderTime from '../table-header/HeaderTime';

const ActivityTime = ({start_time, end_time, day}) => {
    const { selectedDateStr, tableStyles} = useContext(SessionContext);

    const startTime = `${day} ${start_time}`;
    const endTime = `${day} ${end_time}`;
 
    return (
        <div className='ActivityTime'>
            {selectedDateStr ?
                <>{tableStyles !== 'StandardTable' ? 
                    <p>
                        <HourRow item='time' time={`${startTime}`}/> - <HourRow item='time' time={endTime}/> 
                        <span>(<HeaderTime item='header-time'/>)</span>
                    </p> 
                : null}</> :
            null}
        </div>
    )
}

export default ActivityTime
