import React, { useState } from 'react'
import slugify from 'react-slugify';
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseLangLocation from '../../../../components/hook/UseLangLocation'; 
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import Main from '../../../../components/interface/main/Main';
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader';
import Footer from '../../../../components/navegation/footer/Footer';
import Header from '../../../../components/navegation/header/Header';
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'; 
import BiosContent from './BiosContent';
import './biography.css'

const Bios = props => { 
    const [loading, setLoading] = useState(true);
    const {bios_data } = props;
    const title = bios_data.title.rendered
    const status = bios_data.status
    return (
        <UseLangLocation> 
            <Header title={title} url_es={`/es/programa/oradores/${slugify(title)}`} url_pt_br={`/pt-br/programa/palestrantes/${slugify(title)}`} url_en={`/en/programme/speakers/${slugify(title)}`} loading={loading} {...props} />
            <Main className='container' status={status} loading={loading}>
                <section>
                    <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                        <SponsorsSlider/> 
                        <StickyHeader/>  
                        <BiosContent bios_data={bios_data} title={title}/> 
                    </UseTimeOut>
                </section>   
            </Main>
            <OrganizersElement />
            <Footer className="master_foot foot_home" /> 
    </UseLangLocation> 
    )
}

export default Bios
