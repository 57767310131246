import React from 'react'; 
import './sponsors.css'

function SponsorList(props) {
    const { sponsors, className } = props;
    return ( 
        <> {sponsors ? <div className={`${className}`}>
            {sponsors?.map((item, id)=> 
            <a key={id} href={`${item.acf.url}`} target="_blank" rel="noreferrer" title={`${item.title.rendered}`}>
                <img src={`${item.acf.logo}`} alt={`${item.title.rendered}`} /> 
            </a>)}  
        </div> : undefined}
        </>
    )
}

export default SponsorList
