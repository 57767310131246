import React from 'react'
import useUrlParams from '../hook/useUrlParams';
import Modal from '../interface/modal/Modal';
import './announcements.css'
import InfoAboutRegistration from '../../pages/registration/fees/InfoAboutRegistration';

const Announcements = props => {
    const { paramValue, display, /* handleParam,  */closeModal } = useUrlParams('announcements', ['important-information-about-lacnic-39-registration']);

    // Resto del código del componente 
    return (
        <>
            {/* Botones para seleccionar la actividad social */}
            {/* <button onClick={() => handleParam('about-lacnic39-registration')}>
            Información importante sobre registro LACNIC 39
        </button>    */}
            {/* Ventana modal */}
            <Modal onCancel={closeModal} show={display} paramValue={'announcements'} className='announcements_modal'>
                <div className='close_announcements'>
                    <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true" ></i></button>
                </div>
                
                <div className='announcement_content'>
                    {paramValue === 'important-information-about-lacnic-39-registration' ?
                        <InfoAboutRegistration />
                        : undefined}
                </div>

            </Modal>
        </>
    );
}

export default Announcements
