import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader'; 
import UseTitleHTML from '../../../components/hook/UseTitleHTML';
import UseHTML from '../../../components/hook/UseHTML';
import './Networking.css';

const Networking = props => { 
    const { handleID, handleApiURL, handleLoading, data  } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='2488'>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/how-to-participate/networking' url_es='/es/como-participar/networking' url_pt_br='/pt-br/como-participar/networking' {...props} />
                <Main className='container Networking' loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider />
                            <StickyHeader/>
                            <article>
                                <div> 
                                    <h1><UseTitleHTML html={data?.title?.rendered}/></h1>
                                    <UseHTML html={data?.content?.rendered}/> 
                                </div>
                            </article>
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Networking
