import React from 'react'; 
import './footer.css'
import DailyBar from '../../interface/daily-bar/DailyBar';

export default function FooterWrapper(props) { 
    return ( <>
        <DailyBar state={false}/>
        <footer className={`${props.className}`}>
            <div> 
                 {props.children}
            </div>
        </footer>
    </>)
}
  