import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const Player = props => {
    const {content, title} = props;
    return (
        <article className='player'>
            <div> 
                <UseTitleHTML html={title} />
                <UseHTML html={content}/> 
            </div>
        </article>
    )
}

export default Player
