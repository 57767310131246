import React from 'react';
import NavItems from './NavItems'
import './main-nav-menu.css'
import Stand from '../stand/Stand';
import useMenuContext from '../../../hook/UseMenu';


const MainNavMenu = props => {
    const { isMenu, eventID, menuLang, loading } = useMenuContext();
    return (

        <nav className='mainNavMenu main-nav-menu'>
            <ul className='mainNavMenu_items'>
                {!loading ? (
                    <>
                        {isMenu.sort((a, b) => a.acf.id - b.acf.id)?.map((item) => {
                            if (Array.isArray(item.acf.event) && item.acf.event.includes(157)) {
                                return (
                                    <NavItems
                                        key={item.id}
                                        url_api={item.acf.url_api}
                                        id_menu={item.id}
                                        menuLang={menuLang}
                                        eventID={eventID}
                                        item_category={`${item.slug}`}
                                        item_name={`${item.name}`}
                                        {...props}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </>
                ) : undefined}
                <Stand />
            </ul>
        </nav>
    )
}

export default MainNavMenu
