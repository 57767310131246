import React, { useState } from 'react'
import Dropdown from './Dropdown';
import MenuButtonItem from './MenuButtonItem'; 

const NavItems = props => {
    const { item_category, item_name,  eventID, menuLang, id_menu, url_api } = props
    const [isItem, setItem] = useState(false);
 
     
        return (<> 
            <li onMouseEnter={() => setItem(item_category)} 
                onMouseLeave={() => setItem(false)} 
            >
                <MenuButtonItem item_name={item_name} />
                <Dropdown
                    url_api={url_api}
                    id_menu={id_menu}
                    menuLang={menuLang}
                    eventID={eventID}
                    item_category={item_category}
                    isItem={isItem}
                    item_name={item_name}
                />  
            </li>
             </>
        )
    

}

export default NavItems 