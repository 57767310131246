import React from 'react'
import './button.css'

const ExternalButton = props => {
    const {children, to, className, target} = props
    return (
        <a href={to} className={`button ${className}`} target={target} >
            {children}
        </a>
    )
}

export default ExternalButton
