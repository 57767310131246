import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'

const HotelsMap = ({content}) => {
    return (
        <article className='HotelsMap'>
            <div>
               <UseHTML html={content} />
            </div>
        </article>
    )
}

export default HotelsMap
