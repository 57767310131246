import React, { useState } from 'react'
import UseHTML from '../../hook/UseHTML';
import './url-and-code.css'

const UrlAndCode = props => {
    const { code, url, url_text } = props
    const [copyClipBoard, setCopyClipBoard] = useState(true);

    const ClipBoard = () => {
        navigator.clipboard.writeText(code)
        setCopyClipBoard(false)
        setTimeout(() => {
            setCopyClipBoard(true);
        }, 3000);
        setCopyClipBoard(false);
    }
    return (
        <div className='url_and_code'>
            <div>
                <a href={`${url}`} target="_blank" rel="noreferrer">
                <i className="icon-link"></i> <UseHTML html={url_text} />
                </a>
            </div>
            {code !== 'none' ?
            <div className='code'>
                <p className='hash'>#</p>
                <UseHTML className='code_tag' html={code} />
                <button className='copy_clipboard' onClick={() => ClipBoard()}>
                    {copyClipBoard ? <i className="icon-clone"></i> : <i className='icon-check'></i>}
                </button>
            </div>: null}
        </div>
    )
}

export default UrlAndCode
