import React, { useState, useEffect } from 'react';
import { BiographyContext } from './DataContext';
import axios from "axios";


export const BiographyState = ({ children }) => {
  const [bios, setBios] = useState([]);
  const [loading, setLoading ]  = useState([]);
 

  const apiUrl = 'https://apievt.lacnic.net/wp-json/data/biographies';
  const pageParam = '?per_page=100' 
  const lacnic40 = '&our_events=157'


  useEffect(() => {
    const fetchSponsors = async () => {
      setLoading(true);
      const responseJSON = await axios.get(`${apiUrl}${pageParam}${lacnic40}`);
      setBios(responseJSON.data);
      setLoading(false);
    }
    fetchSponsors()
  }, [apiUrl, pageParam, lacnic40])

   

  return (
    <BiographyContext.Provider value={{
      bios,
      loading
    }}>
      {children}
    </BiographyContext.Provider>
  );
} 