import React from 'react'

const FormatSlide = props => {
    const { title, info } = props;
    return (
        <div className='format'>
            <h2>{title}</h2>
            {info &&
                <p className='info'>{info}</p>
            }
        </div>
    )
}

export default FormatSlide
