import React, { useState } from 'react'
import './DailyBar.css'

/* Barra diaria */
const DailyBar = (props) => { 
    const {state} =  props;
    
    const [dailybar, dailybarClick] = useState(false) /* Dailybar acción */ 

    return (
        <>
            {state === true ?
                <div className='DailyBar'>
                    <div>
                        <button onClick={() => dailybarClick('agenda')}>
                            <i className='icon-calendar-days'></i> Lunes 2
                        </button>
                        <button onClick={() => dailybarClick('map')}>
                            <i className='icon-map'></i> Mapa (Salas)
                        </button>
                    </div>
                </div> : null}
            {dailybar === 'map' ? 'map' : null }
            {dailybar === 'agenda' ? 'agenda' : null } 
        </>
    )
}

export default DailyBar
