import React, { useState, useEffect } from 'react'
import axios from "axios";
import UseLang from '../../../../../../components/hook/UseLang';
import Ellipsis from '../../../../../../components/animations/loadings/Ellipsis';
import ModalSlides from './ModalSlides';

const Slides = ({ presentation, title_status }) => {  
    return (
        <>{presentation && <div className='slides'>
            <p className='slides_header'>
                <UseLang es>Presentaciones:</UseLang>
                <UseLang en>Presentation:</UseLang>
                <UseLang pt_br>Apresentação:</UseLang>
            </p>
            {presentation?.map((item, id) => 
                <React.Fragment key={id}> 
                    <SlidesMap item={item} title_status={title_status} />
                </React.Fragment>
            )}
        </div>}</>
    )
}
 

export default Slides

const SlidesMap = ({ item, title_status }) => { 
  
    const [loading, setLoading] = useState(false)
    const [presentationData, setPresentationData] = useState([])
    useEffect(() => {
        const slideFetch = async () => {
            setLoading(false)
            const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/media/${item}`);
            setPresentationData(responseJSON) 
            setLoading(true)
        }
        slideFetch()
    }, [item])

    const handleSlide = (item) => {
        openModal(true)
    }

    const [showSlide, setShowSlide] = useState(false)
    const openModal = () => setShowSlide(true)
    const closeModal = () => setShowSlide(false)

    const author = presentationData?.data?.acf?.author
    const title = presentationData?.data?.acf?.title;
    const source_url = presentationData?.data?.source_url;

    return (
        <>
            {loading ?
                <>
                    <button className="source_url" onClick={() => handleSlide(item)}>
                        {title_status  ? 
                        <>
                         {title ?
                            <><i className='icon-file-pdf-1'></i> {`${title} `}
                                {author ? <>{author.length === 1 ?
                                    <><UseLang es>| Autor:</UseLang>
                                        <UseLang en>| Author:</UseLang>
                                        <UseLang pt_br>| Autor:</UseLang>
                                    </> :
                                    <><UseLang es>| Autores:</UseLang>
                                        <UseLang en>| Authors:</UseLang>
                                        <UseLang pt_br>| Autores:</UseLang>
                                    </>}
                                    {author?.map((item, id) =>
                                        <span key={id}>
                                            {` ${item.post_title}`}
                                        </span>
                                    )}</> 
                                    : undefined}
                            </>
                            : <><i className='icon-file-pdf-1'></i> <UseLang es>Presentación</UseLang><UseLang en>Presentation</UseLang><UseLang pt_br>Apresentação</UseLang></>}
                        </>
                        : <><i className='icon-file-pdf-1'></i> <UseLang es>Presentación</UseLang><UseLang en>Presentation</UseLang><UseLang pt_br>Apresentação</UseLang></>}
                    </button>

                    <ModalSlides showSlide={showSlide} closeModal={closeModal} slide_id={item} url={source_url} />
                </>
                : <Ellipsis />}
        </>
    )
}