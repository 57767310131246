import React, { useState } from 'react';
import LoadBiography from './LoadBiography';
import SelectSpeaker from './SelectSpeaker';

const SpeakerName = props => {
  const { title, presentation_id, modal } = props;

  const [speaker_id, setSpeakerID] = useState('');
  const handleBio = (id) => {
    setSpeakerID(id);
    openModal(true);
  };

  const [showBio, setShowBio] = useState(false);
  const openModal = () => setShowBio(true);
  const closeModal = () => setShowBio(false);

  return (
    <>
      {title && (
        <div className='presentation_speakers'>
          {modal === true ? (
            <>
              {title?.map((item, ID) => (
                <React.Fragment key={ID}>
                  <SelectSpeaker title={item.post_title} onClick={() => handleBio(item.ID)} modal={modal} />
                </React.Fragment>
              ))}
              <LoadBiography presentation_id={presentation_id} speaker_id={speaker_id} show={showBio} onCancel={closeModal} />
            </>
          ) : (
            <>
              {title?.map((item, ID) => (
                <React.Fragment key={ID}>
                  <SelectSpeaker title={item.post_title} />
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SpeakerName;
