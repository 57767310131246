import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'; 
import { Link } from 'react-router-dom';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const ParticipateGrid = props => {
    const { data } = props;
   
    return (
        <>{data ? <article className='howToParticipate_inner'>
            <div>
                <UseTitleHTML html={data.title.rendered} />
                <div className='grid_participate'>
                    {data.acf.apps?.map((item, id) => {
                        const { app_icon, app_name_link, app_link, app_name, app_description } = item;
                        return (
                            <div key={id} className='column_participate'>
                                <div>
                                    <i className={`${app_icon}`}></i>
                                    <p className='app_name'>{app_name}</p>
                                    <UseHTML className="app_info" html={app_description}/> 
                                    {app_link ? <Link to={`${app_link}`} >{`${app_name_link}`}</Link> : ''}

                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </article> 
        : undefined
        }</>
    )
}

export default ParticipateGrid


